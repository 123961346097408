import React, { useState } from 'react';
import { Modal, Select, Space, Button, message, Tag } from 'antd';
import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { ConsumerRes } from '../../types';
import { SuccessIcon } from '../../Assets/Svgs';
import { AddDoublePointsAction } from '../../Actions/ConfigActions';

interface AddDoublePointsModalProps {
  consumers?: ConsumerRes[];
  updatedDouplePoints: number;
  setDoublePoints: React.Dispatch<React.SetStateAction<number>>;
  data: ConsumerRes[];
  getUsers: () => void;
}

const AddDoublePointsModal: React.FC<AddDoublePointsModalProps> = ({
  consumers,
  setDoublePoints,
  data,
  updatedDouplePoints,
  getUsers,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [step, setStep] = useState<number>(1); // Step 1: Selection, Step 2: Success Message
  const [loading, setLoading] = useState<boolean>(false);
  const handleChange = (value: string[]) => {
    setSelectedItems(value);
  };

  const handleLoading = (value: boolean) => {
    setLoading(value);
  };
  const handleModalOk = () => {
    if (selectedItems?.length > 0) {
      AddDoublePointsAction(selectedItems, getUsers, handleLoading);
      setStep(2);
      if (updatedDouplePoints > 0) {
        setDoublePoints(updatedDouplePoints + selectedItems?.length);
      } else {
        setDoublePoints(data?.length + selectedItems?.length);
      }
    } else if (selectedItems?.length === 0) {
      message?.error('Please select at least one user.');
      return;
    }
  };

  const handleModalCancel = () => {
    if (step === 2) {
      setModalVisible(false);
      setSelectedItems([]);
      setStep(1); // Reset to Step 1: Selection
    } else {
      setModalVisible(false);
    }
  };

  const renderOption = (option: ConsumerRes) => (
    <Space>
      {option.name} {option.phone_number}
    </Space>
  );

  return (
    <div>
      <Button
        icon={<PlusOutlined />}
        className="border-none shadow-none text-secondary "
        onClick={() => setModalVisible(true)}
        loading={loading}
      >
        اضافة الى قائمة ضعف النقاط
      </Button>
      <Modal
        open={modalVisible}
        centered
        onCancel={handleModalCancel}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={'27%'}
        closeIcon={<></>}
        styles={{ content: { borderRadius: '24px' } }}
        footer={
          <>
            {step === 1 ? (
              <Button
                size="large"
                type="primary"
                className="w-full rounded-[12px] text-white"
                onClick={handleModalOk}
                disabled={selectedItems.length === 0}
              >
                اضافة
              </Button>
            ) : (
              <></>
            )}
          </>
        }
        title={
          <div>
            <div className="flex flex-row-reverse justify-end items-center">
              <Button
                type="text"
                onClick={handleModalCancel}
                icon={<CloseOutlined />}
              />
            </div>
            <div className="text-center text-neutral90">
              {step === 1 ? (
                'ادخل رقم التليفون للاضافة الى قائمة ضعف النقاط'
              ) : !loading ? (
                <div className="flex flex-col items-center gap-y-4 mt-4">
                  <SuccessIcon />
                  <div className="text-neutral90 w-[60%] font-medium">
                    تمت اضافة الارقام التالية الى قائمة ضعف النقاط بنجاح
                  </div>
                </div>
              ) : (
                <>
                  {' '}
                  <LoadingOutlined className=""></LoadingOutlined>
                </>
              )}
            </div>
          </div>
        }
      >
        {step === 1 ? (
          <Select
            mode="multiple"
            className="w-full "
            size="large"
            allowClear
            placeholder="رقم التليفون"
            value={selectedItems}
            onChange={handleChange}
          >
            {consumers
              ?.filter((consumer) => {
                return consumer.factor_points !== 2;
              }) // Filter out consumers with is_double_points=true
              .map((consumer) => (
                <Select.Option key={consumer.id} value={consumer.id}>
                  {renderOption(consumer)}
                </Select.Option>
              ))}
          </Select>
        ) : !loading ? (
          <div className="flex flex-col  items-center">
            <ul className="flex flex-wrap ">
              {selectedItems.map((selectedId) => {
                const selectedConsumer = consumers?.find(
                  (consumer) => consumer.id === parseInt(selectedId, 10)
                );

                return (
                  <Tag
                    className="rounded-[30px] bg-tag-background text-neutral90 m-1 p-[4px 12px]"
                    key={selectedId}
                  >
                    {selectedConsumer && selectedConsumer.phone_number}
                  </Tag>
                );
              })}
            </ul>
          </div>
        ) : (
          <></>
        )}
      </Modal>
    </div>
  );
};

export default AddDoublePointsModal;
