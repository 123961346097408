import React, { useEffect, useState } from 'react';
import {
  ForgotPasswordPageProps,
  useRouterType,
  useLink,
  useTranslate,
  useRouterContext,
  useNavigation,
} from '@refinedev/core';
import {
  colStyle,
  formContainerStyles,
  pageContainerStyles,
  cardStyles,
  pageTitleStyles,
  submitButtonStyles,
  inputFieldStyles,
  labelStyles,
  inputStyles,
  subTitleStyles,
} from './styles';
import {
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Button,
  LayoutProps,
  CardProps,
  FormProps,
  theme,
} from 'antd';
import { colors } from '../../Theme/Colors';
import { AuthFotter } from '../../components/poweredBy';
import { LogInMainImage } from '../../components/LogInMainImage';
import {
  ForgotPasswordAction,
  VerificationAction,
} from '../../Actions/AuthActions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
type VerificationPageProps = ForgotPasswordPageProps<
  LayoutProps,
  CardProps,
  FormProps
>;

/**
 * **refine** has forgot password page form which is served on `/forgot-password` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/antd-auth-page/#forgot-password} for more details.
 */
export const VerificationPage: React.FC<VerificationPageProps> = ({
  loginLink,
  contentProps,
  formProps,
}) => {
  const { token } = theme.useToken();
  const translate = useTranslate();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();
  const ActiveLink = routerType === 'legacy' ? LegacyLink : Link;
  const { push } = useNavigation();
  const [validUser, setValidUser] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const validOtp = (value?: boolean) => {
    if (value) {
      setValidUser(true);
    } else {
      push('/reset-password');
    }
  };

  const [timer, setTimer] = useState(60); // Initial timer value
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const handleResendClick = () => {
    // Reset timer and start countdown again
    ForgotPasswordAction();
    setTimer(60);
    setIsTimerRunning(true);
  };
  const handlLoading = (value: boolean) => {
    setLoading(value);
  };
  useEffect(() => {
    // eslint-disable-next-line no-undef
    let intervalId: NodeJS.Timeout;
    if (isTimerRunning && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setIsTimerRunning(false);
    }

    // Clean up interval
    return () => clearInterval(intervalId);
  }, [isTimerRunning, timer]);

  return (
    <div style={{ ...pageContainerStyles }}>
      <Row
        wrap={false}
        style={{
          ...formContainerStyles,
        }}
      >
        <Col
          span={14}
          style={{
            ...colStyle,
          }}
        >
          <Card
            style={{
              ...cardStyles,
            }}
            {...(contentProps ?? {})}
            styles={{ body: { padding: '0px 0px' } }}
          >
            <Typography.Title level={3} style={{ ...pageTitleStyles }}>
              أهلًا بك في برنامج برنامج نقاط سايبس
            </Typography.Title>
            <Form
              layout="vertical"
              onFinish={(values) => {
                VerificationAction(
                  { code: values?.code },
                  handlLoading,
                  validOtp
                );
              }}
              requiredMark={false}
              {...formProps}
              style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}
            >
              <Typography.Title
                style={{
                  color: 'black',
                  fontSize: '1.5rem',
                  fontWeight: 600,
                  marginBottom: 0,
                }}
              >
                {'إعادة تعيين كلمة السر'}
              </Typography.Title>
              <Typography.Title style={{ ...subTitleStyles }}>
                لقد قمنا بإرسال الكود على admin@sipes.com
                <br />
                من فضلك قم بمراجعة البريد الوارد
              </Typography.Title>
              <div style={{ ...inputStyles }}>
                <label
                  style={{
                    ...labelStyles,
                  }}
                >
                  {translate('pages.login.fields.otp', 'كود التحقق')}
                </label>
                <Row
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 'auto',
                    width: '100%',
                  }}
                >
                  <Col span={12}>
                    <Form.Item
                      name="code"
                      // noStyle
                      rules={
                        [
                          // {
                          //   required: true,
                          //   message: 'Please enter the verification code',
                          // },
                          // {
                          //   pattern: /^[0-9]{5}$/,
                          //   message: 'Please enter a 5-digit verification code',
                          // },
                        ]
                      }
                    >
                      <Input
                        type="code"
                        size="large"
                        placeholder={'- - - - - -'}
                        style={{
                          ...inputFieldStyles,
                          // borderColor: colors.dangerMain, // Need in integration
                        }}
                        suffix={
                          validUser ? (
                            <ExclamationCircleOutlined
                              style={{ color: colors.danger }}
                            />
                          ) : null
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    style={{
                      marginTop: '0.5rem',
                    }}
                  >
                    <div>
                      <Button
                        type="primary"
                        size="large"
                        style={{
                          padding: '0.5rem 0.75rem',
                          marginRight: '0.625rem',
                        }}
                        onClick={handleResendClick}
                        disabled={isTimerRunning}
                      >
                        إعادة إرسال
                      </Button>
                      {timer > 0 && (
                        <Typography.Text
                          style={{
                            fontSize: '0.875rem',
                            fontWeight: 600,
                            marginRight: '0.625rem',
                          }}
                        >
                          بعد{' '}
                          <Typography.Text
                            style={{ color: colors.secondaryColor }}
                          >
                            00:{timer < 10 ? `0${timer}` : timer}
                          </Typography.Text>
                        </Typography.Text>
                      )}
                    </div>
                  </Col>
                </Row>
                {validUser && (
                  <Typography.Text style={{ color: colors.danger }}>
                    كود التحقق غير صحيح
                  </Typography.Text>
                )}
              </div>
              <Form.Item
                style={{
                  // marginTop: '24px',
                  marginBottom: 0,
                }}
              >
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                  style={{
                    ...submitButtonStyles,
                    marginBottom: '0px',
                    marginTop: '0px',
                  }}
                  loading={loading}
                  // disabled={disabled}
                >
                  {translate('pages.forgotPassword.buttons.submit', 'تحقق')}
                </Button>
              </Form.Item>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {loginLink ?? (
                  <Typography.Text
                    style={{
                      fontSize: 12,
                      marginLeft: 'auto',
                    }}
                  >
                    <ActiveLink
                      style={{
                        fontWeight: 'bold',
                        color: token.colorPrimaryTextHover,
                      }}
                      to="/login"
                    >
                      {translate('pages.login.signin', 'تسجيل الدخول')}
                    </ActiveLink>
                  </Typography.Text>
                )}
              </div>
            </Form>
          </Card>
          <AuthFotter />
        </Col>
        <LogInMainImage />
      </Row>
    </div>
  );
};
