import { AuthBindings } from '@refinedev/core';
import { AccountStore, ConfigsStore } from './Store';

export const TOKEN_KEY = 'refine-auth';

export const authProvider: AuthBindings = {
  login: async ({ username, email, password }) => {
    if ((username || email) && password) {
      localStorage.setItem(TOKEN_KEY, username);
      return {
        success: true,
        redirectTo: '/',
      };
    }

    return {
      success: false,
      error: {
        name: 'LoginError',
        message: 'Invalid username or password',
      },
    };
  },
  logout: async () => {
    localStorage.removeItem(TOKEN_KEY);
    if (await ConfigsStore.get('logged_in')) {
      await AccountStore.set('account', {});
      await ConfigsStore.set('logged_in', false);
      await ConfigsStore.set('rememberMe', false);
      await ConfigsStore.set('adminRole', '');
      await ConfigsStore.set('resetPasswordEmail', '');
    }
    sessionStorage.clear();
    return {
      success: true,
      redirectTo: '/login',
    };
  },
  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: '/login',
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return {
        id: 1,
        name: token,
      };
    }
    return token;
  },
  onError: async (error) => {
    if (error.status === 401 || error.status === 403) {
      return {
        logout: true,
        redirectTo: '/login',
        error,
      };
    }

    return {};
  },
};
