import React, { useState } from 'react';
import {
  LoginPageProps,
  LoginFormTypes,
  useLink,
  useRouterType,
  useTranslate,
  useRouterContext,
  useNavigation,
} from '@refinedev/core';
import {
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Button,
  CardProps,
  LayoutProps,
  FormProps,
  theme,
  Checkbox,
} from 'antd';
import { AuthFotter } from '../../components/poweredBy';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  cardStyles,
  colStyle,
  formContainerStyles,
  formStyles,
  inputContainerStyles,
  inputFieldStyles,
  inputStyles,
  labelStyles,
  pageContainerStyles,
  pageTitleStyles,
  rememberCheckboxStyles,
  submitButtonStyles,
} from './styles';
import { colors } from '../../Theme/Colors';
import { ConfigsStore } from '../../Store';
import { LoginAction } from '../../Actions/AuthActions';
import { LogInMainImage } from '../../components/LogInMainImage';
type LoginProps = LoginPageProps<LayoutProps, CardProps, FormProps>;
/**
 * **refine** has a default login page form which is served on `/login` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/antd-auth-page/#login} for more details.
 */
export const LoginPage: React.FC<LoginProps> = ({
  contentProps,
  formProps,
}) => {
  const { token } = theme.useToken();
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [validUser, setValidUser] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm<LoginFormTypes>();
  const translate = useTranslate();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();
  const ActiveLink = routerType === 'legacy' ? LegacyLink : Link;
  const { push } = useNavigation();
  const [formItems, setFormItems] = useState({ email: '', password: '' });
  const navigateToHomeScreen = (response: boolean) => {
    if (!response) {
      push('/');
    }
    setValidUser(response);
  };
  const handleFormChange = (fieldName: string, value: string) => {
    setFormItems((prevFormItems) => ({
      ...prevFormItems,
      [fieldName]: value,
    }));
  };
  const handleLoading = (value: boolean) => {
    setLoading(value);
  };
  return (
    <div style={{ ...pageContainerStyles }}>
      <Row
        wrap={false}
        style={{
          ...formContainerStyles,
        }}
      >
        <Col
          span={14}
          style={{
            ...colStyle,
          }}
        >
          <Card
            style={{
              ...cardStyles,
            }}
            {...(contentProps ?? {})}
            styles={{ body: { padding: '0px 0px' } }}
          >
            {/* {renderProviders()} // For integration */}
            <Typography.Title level={3} style={{ ...pageTitleStyles }}>
              أهلًا بك في برنامج برنامج نقاط سايبس
            </Typography.Title>
            <Form
              form={form}
              onFinish={(values) =>
                LoginAction(values, navigateToHomeScreen, handleLoading)
              }
              requiredMark={false}
              initialValues={{
                remember: false,
              }}
              style={{
                ...formStyles,
              }}
              {...formProps}
            >
              <div style={{ ...inputContainerStyles }}>
                <div
                  style={{
                    ...inputStyles,
                  }}
                >
                  <label
                    style={{
                      ...labelStyles,
                    }}
                  >
                    {translate('pages.login.fields.email', 'الإيميل')}
                  </label>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: 'الإيميل مطلوب' },
                      {
                        type: 'email',
                        message: 'الإيميل غير صالح',
                      },
                    ]}
                  >
                    <Input
                      placeholder={translate(
                        'pages.login.fields.email',
                        'الإيميل'
                      )}
                      onChange={(e) =>
                        handleFormChange('email', e.target.value)
                      }
                      style={{
                        ...inputFieldStyles,
                        borderColor: validUser ? colors.dangerMain : '',
                      }}
                      suffix={
                        validUser ? (
                          <ExclamationCircleOutlined
                            style={{ color: colors.danger }}
                          />
                        ) : null
                      }
                    />
                  </Form.Item>
                </div>
                <div
                  style={{
                    ...inputStyles,
                  }}
                >
                  <label
                    style={{
                      ...labelStyles,
                    }}
                  >
                    {translate('pages.login.fields.password', 'كلمة السر')}
                  </label>
                  <Form.Item
                    name="password"
                    rules={[
                      { required: true, message: 'كلمة السر مطلوبة' },
                      {
                        type: 'regexp',
                        message: 'كلمة السر غير صالحة',
                        pattern:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                      },
                    ]}
                  >
                    <Input.Password
                      type="password"
                      autoComplete="current-password"
                      placeholder="كلمة السر"
                      onChange={(e) =>
                        handleFormChange('password', e.target.value)
                      }
                      style={{
                        ...inputFieldStyles,
                        borderColor: validUser ? colors.dangerMain : '',
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              {validUser && (
                <Typography.Text style={{ color: colors.danger }}>
                  خطأ في الإيميل أو كلمة السر
                </Typography.Text>
              )}
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox
                  style={{
                    ...rememberCheckboxStyles,
                  }}
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                >
                  {'تذكرني'}
                </Checkbox>
              </Form.Item>
              <Form.Item shouldUpdate className="submit">
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  style={{
                    ...submitButtonStyles,
                  }}
                  onClick={() => {
                    if (rememberMe) {
                      ConfigsStore.set('rememberMe', true);
                    } else {
                      sessionStorage.setItem('logoutOnEndSession', 'true');
                    }
                  }}
                  loading={loading}
                  disabled={!formItems.email || !formItems.password}
                >
                  تسجيل الدخول
                </Button>
              </Form.Item>
              <ActiveLink
                style={{
                  color: token.colorPrimaryTextHover,
                  fontSize: '12px',
                  marginLeft: 'auto',
                  fontWeight: '600',
                }}
                to="/forgot-password"
              >
                نسيت كلمة السر؟
              </ActiveLink>
            </Form>
          </Card>

          <AuthFotter />
        </Col>
        <LogInMainImage />
      </Row>
    </div>
  );
};
