import React from 'react';
import {
  useTranslate,
  useUserFriendlyName,
  useRefineContext,
  useResource,
} from '@refinedev/core';
import { Breadcrumb, PageHeader } from '@refinedev/antd';
import type { ListProps } from '@refinedev/antd';

/**
 * `<List>` provides us a layout for displaying the page.
 * It does not contain any logic but adds extra functionalities like a refresh button.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/basic-views/list} for more details.
 */
export const List: React.FC<ListProps> = ({
  title,
  children,
  resource: resourceFromProps,
  wrapperProps,
  contentProps,
  headerProps,
  breadcrumb: breadcrumbFromProps,
}) => {
  const translate = useTranslate();
  const { options: { breadcrumb: globalBreadcrumb } = {} } = useRefineContext();

  const getUserFriendlyName = useUserFriendlyName();

  const { resource, identifier } = useResource(resourceFromProps);

  const breadcrumb =
    typeof breadcrumbFromProps === 'undefined'
      ? globalBreadcrumb
      : breadcrumbFromProps;

  return (
    <div {...(wrapperProps ?? {})}>
      <PageHeader
        ghost={false}
        title={
          title ??
          translate(
            `${identifier}.titles.list`,
            getUserFriendlyName(
              resource?.meta?.label ??
                resource?.options?.label ??
                resource?.label ??
                identifier,
              'plural'
            )
          )
        }
        breadcrumb={
          typeof breadcrumb !== 'undefined' ? (
            <>{breadcrumb}</> ?? undefined
          ) : (
            <Breadcrumb />
          )
        }
        {...(headerProps ?? {})}
      >
        <div {...(contentProps ?? {})}>{children}</div>
      </PageHeader>
    </div>
  );
};
