import React, { useState } from 'react';
import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  TimePicker,
  Calendar,
  Tag,
} from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelect, useTable } from '@refinedev/antd';
import dayjs, { Dayjs } from 'dayjs';
import CalendarHeader from './CalendarHeader';
import { CreateNotificationActionType } from '../../types';
import { SuccessIcon } from '../../Assets/Svgs';
import '../styles.css';
import { CreateNotificationAction } from '../../Actions/ConfigActions';
import { disabledTime, toArabicNumerals } from '../../Helpers';
import { monthsArabic } from '../../Constants';

interface CreateNotificationModalProps {
  refetch: () => void;
}
const CreateNotificationModal: React.FC<CreateNotificationModalProps> = ({
  refetch,
}) => {
  const [form] = Form.useForm();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [formData, setFormData] = useState<any>({
    title: '',
    body: '',
    recipients: false,
    date: dayjs('00-00-0000', 'DD-MM-YYYY'),
    time: dayjs('00:00', 'HH:mm'),
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [targetUser, setTargetUser] = useState<string>('allUsers');
  const [step, setStep] = useState<number>(1);
  const [done, setDone] = useState<boolean>(false);
  const [scheduled, setScheduled] = useState<boolean>(false);
  const [showScheduledMessage, setShowScheduledMessage] =
    useState<boolean>(false);
  const handleChange = (value: number[]) => setSelectedItems(value);
  const { tableProps: conumerData } = useTable({
    resource: 'account-cruds',
    filters: {
      initial: [{ field: 'account_type', operator: 'eq', value: 'consumer' }],
    },
  });
  const { selectProps } = useSelect({
    resource: 'account-cruds',
    optionValue: 'id',
    //@ts-ignore
    optionLabel: (item: {
      name: string;
      phone_number: string;
      id: string;
    }): string => {
      return `${item.name} ${item.phone_number}`;
    },
    onSearch: (value) => [
      {
        field: 'search_field',
        operator: 'eq',
        value,
      },
    ],
  });
  const closeModal = () => {
    setModalVisible(false);
    setSelectedItems([]);
    setTargetUser('allUsers');
    setDone(false);
    setStep(1);
    setScheduled(false);
    form.resetFields();
  };

  const handleDone = () => {
    setDone(true);
    const combinedDateTime = dayjs(
      `${formData.date.format('YYYY-MM-DD')}T${formData.time.format('HH:mm:ss')}`
    );
    CreateNotificationAction({
      is_for_all: targetUser === 'allUsers' ? true : false,
      accounts_ids: selectedItems,
      body: formData.body,
      title: formData.title,
      date: combinedDateTime,
    });
    refetch();
    form.resetFields();
  };

  const sendNow = (values: CreateNotificationActionType) => {
    setScheduled(false);
    setDone(true);
    CreateNotificationAction({
      is_for_all: values.recipients ? false : true,
      accounts_ids: selectedItems,
      body: values.body,
      title: values.title,
    });
    form.resetFields();
  };

  const scheduledNotification = () => {
    if (formData.title.length > 0 && formData.body.length > 0) {
      setScheduled(true);
      setShowScheduledMessage(true);
      setStep(2);
    } else {
      form.validateFields(['title', 'body']);
    }
  };

  const disabledDate = (current: Dayjs) => current.isBefore(dayjs(), 'day');

  const modalTitle = (
    <div>
      <div className="flex flex-row-reverse justify-end items-center">
        <Button type="text" onClick={closeModal} icon={<CloseOutlined />} />
      </div>
    </div>
  );

  const renderSendNowButton = () => (
    <Button
      size="large"
      type="primary"
      className="w-full rounded-[12px] text-white"
      htmlType="submit"
    >
      ارسال الان
    </Button>
  );

  const renderScheduledButton = () => (
    <Button
      size="large"
      className="w-full border-none shadow-none text-primary"
      onClick={scheduledNotification}
    >
      جدولة الارسال
    </Button>
  );

  const renderDoneContent = () => {
    // Map selected IDs to their corresponding phone numbers
    const selectedPhoneNumbers = selectedItems
      .map((itemId) => {
        const selectedConsumer = conumerData.dataSource?.find(
          (consumer) => consumer.id === itemId
        );
        return selectedConsumer ? selectedConsumer.phone_number : null;
      })
      .filter((phoneNumber) => phoneNumber !== null);

    return (
      <div className="flex flex-col items-center gap-y-4 mt-4">
        <SuccessIcon />
        <div className="text-neutral90 w-[60%] font-medium text-lg align-middle text-center">
          {targetUser === 'allUsers'
            ? 'تم ارسال الاشعار بنجاح الى جميع المستهلكين'
            : ' تم ارسال الاشعار بنجاح الى الأرقام التالية'}
        </div>
        {selectedPhoneNumbers.length > 0 && (
          <ul className="flex flex-wrap">
            {selectedPhoneNumbers.map((phoneNumber) => (
              <Tag
                className="rounded-[30px] bg-tag-background text-neutral90 m-1 p-[4px 12px]"
                key={phoneNumber}
              >
                {phoneNumber}
              </Tag>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const renderScheduledMessage = () =>
    showScheduledMessage && (
      <div className="flex flex-col items-center text-nueutral90 font-medium text-lg gap-3">
        <div>سوف يتم ارسال الاشعار في </div>
        <div>
          {`${toArabicNumerals(formData.date.date())}   ${monthsArabic[formData.date.month()]} ${toArabicNumerals(formData.date.year())} ${formData.time.format('HH:mm')}`}
        </div>
      </div>
    );

  return (
    <>
      <Button
        icon={<PlusOutlined />}
        className="border-none shadow-none text-secondary font-semibold leading-6"
        onClick={() => setModalVisible(true)}
      >
        ارسال اشعار
      </Button>
      <Modal
        open={modalVisible}
        centered
        width={'31%'}
        title={modalTitle}
        closeIcon={false}
        footer={null}
        styles={{ content: { borderRadius: '24px' } }}
      >
        {!done ? (
          <Form
            form={form}
            onFinish={(values) => {
              step === 1 ? sendNow(values) : handleDone();
            }}
          >
            {step === 1 ? (
              <div>
                <Form.Item label="ارسال الى" name="recipients">
                  <Radio.Group
                    style={{ flexDirection: 'row' }}
                    defaultValue={'allUsers'}
                    onChange={(e) => setTargetUser(e.target.value)}
                  >
                    <Radio className="" value={'allUsers'}>
                      الجميع
                    </Radio>
                    <Radio value={'someUsers'}>بعض المستهلكين</Radio>
                  </Radio.Group>
                </Form.Item>
                {targetUser === 'someUsers' && (
                  <div className="flex flex-col gap-4">
                    <div>ادخل ارقام التليفون للمستهلكين</div>
                    <Form.Item
                      name="usersToSend"
                      rules={[
                        {
                          required: true,
                          message: 'ادخل ارقام التليفون للمستهلكين',
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        className="w-full"
                        size="large"
                        allowClear
                        placeholder="رقم التليفون"
                        // @ts-ignore
                        onChange={handleChange}
                        variant="filled"
                        {...selectProps}
                      />
                    </Form.Item>
                  </div>
                )}
                <div className="flex flex-col gap-4">
                  <div>عنوان الاشعار</div>
                  <Form.Item
                    name="title"
                    rules={[{ required: true, message: 'عنوان الاشعار مطلوب' }]}
                  >
                    <Input
                      onChange={(e) =>
                        setFormData({ ...formData, title: e.target.value })
                      }
                      variant="filled"
                      placeholder="عنوان الاشعار"
                      className="border-solid rounded border-[#C2C2C2]"
                      size="large"
                    />
                  </Form.Item>
                </div>
                <div className="flex flex-col gap-4">
                  <div className="text-Neutral90 ">نص الاشعار</div>
                  <Form.Item
                    name="body"
                    rules={[{ required: true, message: 'نص الاشعار مطلوب' }]}
                  >
                    <Input.TextArea
                      variant="filled"
                      placeholder="نص الاشعار"
                      className="border-solid rounded border-[#C2C2C2]"
                      size="large"
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      onChange={(e) =>
                        setFormData({ ...formData, body: e.target.value })
                      }
                    />
                  </Form.Item>
                </div>
                <div className="flex flex-col gap-4">
                  {renderSendNowButton()}
                  {renderScheduledButton()}
                </div>
              </div>
            ) : (
              <div>
                <div className="flex flex-col items-center">
                  اختار تاريخ ووقت ارسال الاشعار
                </div>
                <Form.Item name={'date'} rules={[{ required: true }]}>
                  <Calendar
                    fullscreen={false}
                    onSelect={(e) => setFormData({ ...formData, date: e })}
                    headerRender={(props) => <CalendarHeader {...props} />}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
                <div className="flex gap-4">
                  <div className="text-gunmetal-gray border h-min py-2.5 px-3.5 rounded-md font-semibold">
                    الوقت
                  </div>
                  <div className="w-full">
                    <Form.Item name={'time'} rules={[{ required: true }]}>
                      <TimePicker
                        placeholder="الوقت"
                        defaultValue={dayjs('10:00', 'HH:mm')}
                        onChange={(e) => setFormData({ ...formData, time: e })}
                        size="large"
                        disabledTime={disabledTime}
                        variant="filled"
                        className="w-full text-textPrimary900  rounded-md border border-solid border-gray-300 bg-white shadow-xs py-[0.570rem] px-3.5"
                      />
                    </Form.Item>
                  </div>
                </div>
                <Button
                  size="large"
                  type="primary"
                  className="w-full rounded-[12px] text-white"
                  htmlType="submit"
                >
                  جدولة
                </Button>
              </div>
            )}
          </Form>
        ) : !scheduled ? (
          renderDoneContent()
        ) : (
          renderScheduledMessage()
        )}
      </Modal>
    </>
  );
};

export default CreateNotificationModal;
