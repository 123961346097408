import * as React from 'react';
import { useMenuStore } from '../Store/IconsStore';

interface Props {
  selected?: boolean;
}

const DashboardIcon = (props: Props) => {
  const { isSelected } = useMenuStore();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      {...props}
    >
      <path
        fill={isSelected === '/account-cruds/graph-data' ? 'white' : '#424242'}
        d="M4.643 2.5C3.459 2.5 2.5 3.46 2.5 4.643v2.143c0 1.183.96 2.143 2.143 2.143h2.143c1.183 0 2.143-.96 2.143-2.143V4.643c0-1.184-.96-2.143-2.143-2.143H4.643ZM4.643 11.071c-1.184 0-2.143.96-2.143 2.143v2.143c0 1.184.96 2.143 2.143 2.143h2.143c1.183 0 2.143-.96 2.143-2.143v-2.143c0-1.183-.96-2.143-2.143-2.143H4.643ZM11.071 4.643c0-1.184.96-2.143 2.143-2.143h2.143c1.184 0 2.143.96 2.143 2.143v2.143c0 1.183-.96 2.143-2.143 2.143h-2.143a2.143 2.143 0 0 1-2.143-2.143V4.643ZM14.286 11.071c.591 0 1.071.48 1.071 1.072v1.071h1.072a1.071 1.071 0 0 1 0 2.143h-1.072v1.072a1.071 1.071 0 0 1-2.143 0v-1.072h-1.071a1.071 1.071 0 1 1 0-2.143h1.071v-1.071c0-.592.48-1.072 1.072-1.072Z"
      />
    </svg>
  );
};
export default DashboardIcon;
