import React from 'react';
import { ConfigProvider, theme } from 'antd';
import { PropsWithChildren, createContext, useEffect, useState } from 'react';
import { colors } from '../../Theme/Colors';

type ColorModeContextType = {
  mode: string;
  setMode: (mode: string) => void;
};

export const ColorModeContext = createContext<ColorModeContextType>(
  {} as ColorModeContextType
);

export const ColorModeContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const colorModeFromLocalStorage = localStorage.getItem('colorMode');
  const isSystemPreferenceDark = window?.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches;

  const systemPreference = isSystemPreferenceDark ? 'dark' : 'light';
  const [mode, setMode] = useState(
    colorModeFromLocalStorage || systemPreference
  );

  useEffect(() => {
    window.localStorage.setItem('colorMode', mode);
  }, [mode]);

  const setColorMode = () => {
    if (mode === 'light') {
      setMode('dark');
    } else {
      setMode('light');
    }
  };

  const { defaultAlgorithm } = theme;

  return (
    <ColorModeContext.Provider
      value={{
        setMode: setColorMode,
        mode,
      }}
    >
      <ConfigProvider
        // you can change the theme colors here. example: ...RefineThemes.Magenta,
        theme={{
          algorithm: defaultAlgorithm,
          token: {
            fontFamily: 'Noto Sans Arabic',
            fontFamilyCode: 'Noto Sans Arabic',
            colorPrimary: colors.primaryColor,
            colorBgContainerDisabled: colors.disableGrey,
            fontSize: 14,
            colorBgLayout: 'white',
            colorTextHeading: colors.primaryColor,
            fontSizeHeading4: 24,
            paddingLG: 16,
          },
        }}
        direction="rtl"
      >
        {children}
      </ConfigProvider>
    </ColorModeContext.Provider>
  );
};
