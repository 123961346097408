import React from 'react';
import {
  useNavigation,
  useRefineContext,
  useResource,
  useRouterType,
  useBack,
  BaseKey,
} from '@refinedev/core';
import { Breadcrumb, PageHeader } from '@refinedev/antd';
import { Card, Spin } from 'antd';
import type { ShowProps } from '@refinedev/antd';
import '../../pages/styles.css';
import { ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons';
import { convertToArabicNumbers } from '../../Helpers';
/**
 * `<Show>` provides us a layout for displaying the page.
 * It does not contain any logic but adds extra functionalities like a refresh button.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/basic-views/show} for more details.
 */

interface IShowProps extends ShowProps {
  consumerId?: BaseKey;
}

export const Show: React.FC<IShowProps> = ({
  title,
  isLoading = false,
  children,
  resource: resourceFromProps,
  breadcrumb: breadcrumbFromProps,
  contentProps,
  headerProps,
  wrapperProps,
  consumerId,
}) => {
  const { options: { breadcrumb: globalBreadcrumb } = {} } = useRefineContext();

  const routerType = useRouterType();
  const back = useBack();
  const { goBack } = useNavigation();

  const { action } = useResource(resourceFromProps);

  const breadcrumb =
    typeof breadcrumbFromProps === 'undefined'
      ? globalBreadcrumb
      : breadcrumbFromProps;

  return (
    <div {...(wrapperProps ?? {})}>
      <PageHeader
        ghost={false}
        className="page-header"
        backIcon={
          <div className="showParentDev">
            <ArrowRightOutlined size={10} />
            <span>{'العودة'}</span>
          </div>
        }
        onBack={
          action !== 'list' && typeof action !== 'undefined'
            ? routerType === 'legacy'
              ? goBack
              : back
            : undefined
        }
        title={
          <div className="showHeader">
            <div>
              {consumerId ? (
                `(${convertToArabicNumbers(consumerId)})`
              ) : (
                <LoadingOutlined />
              )}
            </div>
            <div> {title ? title : <LoadingOutlined />}</div>
          </div>
        }
        breadcrumb={
          typeof breadcrumb !== 'undefined' ? (
            <>{breadcrumb}</> ?? undefined
          ) : (
            <Breadcrumb />
          )
        }
        {...(headerProps ?? {})}
      >
        <Spin spinning={isLoading}>
          <Card bordered={false} {...(contentProps ?? {})}>
            {children}
          </Card>
        </Spin>
      </PageHeader>
    </div>
  );
};
