//@ts-nocheck
import { AccountStore, ConfigsStore } from '../Store';
import { API_KEY, bundle_id } from '../configs';
import axios from 'axios';
import { AxiosHeaders, DAdmin } from '../types';
import dayjs from 'dayjs';
import { apiRequest, apiRequestPost } from '../rest-data-provider/utils/APIs';
import { TOKEN_KEY } from '../authProvider';
export const axiosInstance = axios.create() as AxiosHeaders;
const setHeaders = async () => {
  try {
    const account = await AccountStore.get('account');

    if (account && account.tokens?.access_token) {
      axiosInstance.defaults.headers = {
        api_key: API_KEY,
        Authorization: `Bearer ${account?.tokens?.access_token}`,
        bundle_id: bundle_id,
      };
    } else {
      axiosInstance.defaults.headers = {
        api_key: API_KEY,
      };
    }
  } catch (error) {
    error;
  }
};
setHeaders();
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      // Clear the token or any other relevant data
      localStorage.removeItem(TOKEN_KEY);
      AccountStore.set('account', {});
      ConfigsStore.set('logged_in', false);

      // Redirect to login page
      window.location.href = '/login'; // Or use a method provided by your routing library
    }
    return Promise.reject(error);
  }
);
export const LoginAction = async (
  loginInput: {
    email: string;
    password: string;
  },
  navigateToHomeScreen: (response: boolean) => void,
  handleLoading: (value: boolean) => void
) => {
  handleLoading(true);
  try {
    const { data: accountData } = await apiRequest<DAdmin>({
      url: '/admin/login',
      data: loginInput,
      method: 'POST',
    });
    await AccountStore.set('account', accountData);
    await ConfigsStore.set('logged_in', true);
    await ConfigsStore.set('resetPasswordEmail', '');
    if (await ConfigsStore.get('rememberMe')) {
      const cookies = {
        value: await ConfigsStore.get('logged_in'),
        expiry: dayjs(Date()).add(15, 'd').toDate(),
      };
      await ConfigsStore.set('cookieExpiration', cookies);
    }
    axiosInstance.defaults.headers = {
      // @ts-ignore
      Authorization: `Bearer ${accountData.tokens?.access_token}`,
      api_key: API_KEY,
    };
    localStorage.setItem(TOKEN_KEY, accountData.name);
    handleLoading(false);
    navigateToHomeScreen(false);
    return {
      success: true,
      message: 'Login Successful',
      redirectTo: '/',
    };
  } catch (error) {
    navigateToHomeScreen(true);
    handleLoading(false);
    return {
      success: false,
      error: {
        name: 'خطأ في الإيميل أو كلمة السر',
        message: `خطأ في تسجيل الدخول`,
      },
    };
  }
};
export const ForgotPasswordAction = async (
  handleLoading?: (value: boolean) => void,
  email?: string,
  validEmail?: (value?: boolean) => void
) => {
  handleLoading && handleLoading(true);
  try {
    await apiRequestPost<boolean>({
      url: '/admin/forget-password',
      data: email ? email : await ConfigsStore.get('resetPasswordEmail'),
    });
    await ConfigsStore.set('resetPasswordEmail', email);
    handleLoading && handleLoading(false);
    if (validEmail) {
      validEmail();
    }
  } catch (error) {
    handleLoading && handleLoading(false);
    if (validEmail) {
      validEmail(true);
    }
  }
};

export const VerificationAction = async (
  code: { code: string },
  handleLoading: (value: boolean) => void,
  validOtp: (value?: boolean) => void
) => {
  handleLoading(true);
  try {
    const resetPasswordEmail = await ConfigsStore.get('resetPasswordEmail');
    const response = await apiRequestPost({
      url: '/admin/verify-code',
      data: {
        email: resetPasswordEmail?.email,
        code: code.code,
      },
    });
    if (response.status === 201 && response.data) {
      await AccountStore.set('account', response.data);
      validOtp();
    }
    handleLoading(false);
  } catch (error: any) {
    handleLoading(false);
    validOtp(true);
    return {
      success: false,
      error: {
        name: 'Verification Error',
        message: `${error}`,
      },
    };
  }
};

export const ResetPasswordAction = async (
  password: string,
  navigateToLogIn: (status: boolean) => void,
  handleLoading: (value: boolean) => void
) => {
  handleLoading(true);
  const inputs = { password };
  await AccountStore.get('account');
  try {
    await apiRequestPost<boolean>({
      url: '/admin/reset-password',
      data: inputs,
      manualTrigger: true,
    });
    await AccountStore.set('account', {});
    await ConfigsStore.set('resetPasswordEmail', '');
    handleLoading(false);
    navigateToLogIn(true);
  } catch (error: any) {
    handleLoading(false);
    navigateToLogIn(false);
    return {
      success: false,
      error: {
        name: 'Verification Error',
        message: `${error}`,
      },
    };
  }
};

export const CreateDeviceAction = async () => {
  const language = 'ar';
  const os = 'browser';

  const currentDevice = await ConfigsStore.get('device_id');
  if (currentDevice) return;
  try {
    const { data: deviceData } = await apiRequest<{ id: string }>({
      url: '/admin/create-device',
      method: 'POST',
      data: {
        os: os,
        language: language,
      },
    });
    if (!deviceData) throw new Error('Something went wrong!');
    await ConfigsStore.set('device_id', deviceData?.id);
  } catch (error: any) {
    throw new Error('Error');
  }
};
