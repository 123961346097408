import React, { useState } from 'react';
import {
  useActiveAuthProvider,
  useGetIdentity,
  useLogout,
} from '@refinedev/core';
import { Button, Popover } from 'antd';
import { Text } from '../../text';
import { colors } from '../../../Theme/Colors';
import UserIcon from '../../../Assets/UserIcon';
import { ArrowUp, ArrowDown, Logout } from '../../../Assets/Svgs';
import { centerFlex } from '../styles';

export const CurrentUser: React.FC = () => {
  const [opened, setOpened] = useState(false);
  const { mutate: logout } = useLogout();
  const authProvider = useActiveAuthProvider();
  const { data: user } = useGetIdentity({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });
  const content = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          padding: '4px',
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
        }}
      >
        <Button
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
          icon={<Logout />}
          type="text"
          onClick={() => logout()}
          dir="rtl"
        >
          تسجيل الخروج
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <Popover
        placement="bottomRight"
        content={content}
        trigger="click"
        overlayInnerStyle={{ padding: 0 }}
        overlayStyle={{ zIndex: 999, width: '178px', height: '64px' }}
        onOpenChange={(newOpen) => setOpened(newOpen)}
        style={{ display: 'flex', alignItems: 'center' }}
        arrow={false}
      >
        <Button
          dir="rtl"
          icon={<UserIcon />}
          style={{
            ...centerFlex,
            border: 0,
            backgroundColor: colors.sideBarGray,
            width: '100%',
            height: '2.62rem',
            textAlign: 'center',
            gap: '0.5rem',
          }}
        >
          <Text
            color={colors.tableHeader}
            style={{ fontWeight: '600' }}
            size="sm"
          >
            أهلًا، {user?.name}
          </Text>
          {opened ? <ArrowUp /> : <ArrowDown />}
        </Button>
      </Popover>
    </>
  );
};
