import { Button, Form, Input, List, Modal, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { DeleteIcon } from '../../Assets/Svgs';
import { RemoveDoublePointsAction } from '../../Actions/ConfigActions';
import { ConsumerRes } from '../../types';

interface DoublePointsListType {
  updatedDouplePoints: number;
  setUpdatedDouplePoints: React.Dispatch<React.SetStateAction<number>>;
  usersData: ConsumerRes[];
  getUsers: () => void;
}

const DoublePointsList: React.FC<DoublePointsListType> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteState, setDeleteState] = useState(false);
  const { getUsers } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUsersRef = useRef(getUsers);
  useEffect(() => {
    getUsersRef.current();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
    props.getUsers();
  };

  const hideModal = () => {
    setIsModalOpen(false);
    props.getUsers();
  };
  const handleFinish = (loading: boolean, state: boolean) => {
    if (state) {
      setDeleteState(state);
    }
    setLoading(loading);
  };
  const deleteFromList = (id: string) => {
    RemoveDoublePointsAction(id, handleFinish, props.getUsers);
    if (deleteState) {
      setDeleteState(false);
    }
  };

  const rowClassName = () => {
    return 'border-b-0 bg-white';
  };

  return (
    <>
      <div className="flex justify-center items-center">
        <Button
          className="border-none shadow-none text-secondary"
          onClick={showModal}
        >
          قائمة ضعف النقاط
        </Button>
        <div className="text-secondary bg-sidebar-gray px-3 rounded">
          {props.usersData.length}
        </div>
      </div>

      <Modal
        onCancel={hideModal}
        open={isModalOpen}
        width={'30%'}
        footer={null}
        destroyOnClose={true}
        styles={{
          content: {
            borderRadius: '24px',
          },
        }}
        closeIcon={<></>}
        title={
          <div className="flex flex-col gap-y-4	 ">
            <div className="flex flex-row-reverse justify-end items-center">
              <Button
                type="text"
                onClick={hideModal}
                icon={<CloseOutlined />}
              />
            </div>
            <div className="text-center text-neutral90">قائمة ضعف النقاط</div>
            <div className="mt-4">
              <Form>
                <Form.Item id="phone_number">
                  <Input
                    placeholder="ابحث في المستهلكين"
                    size="large"
                    suffix={<SearchOutlined />}
                    type="number"
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
        }
      >
        <List>
          <Table
            dataSource={props.usersData}
            loading={props.usersData.length < 0 ? true : false}
            pagination={{
              position: ['bottomCenter'],
              nextIcon: <></>,
              prevIcon: <></>,
              size: 'small',
              pageSize: 5,
            }}
            rowClassName={rowClassName}
            bordered={false}
            showHeader={false}
          >
            <Table.Column
              dataIndex={'number'}
              title={<div className="hidden"></div>}
              align="right"
              render={(
                value,
                record: {
                  name: string;
                  number: string;
                  id: string;
                  phone_number: string;
                }
              ) => (
                <div className="flex justify-between ">
                  <div>
                    {record?.phone_number} {record?.name}
                  </div>
                  <div>
                    <Button
                      className="border-none shadow-none"
                      onClick={() => {
                        deleteFromList(record?.id);
                        props.setUpdatedDouplePoints(
                          props.updatedDouplePoints - 1
                        );
                      }}
                      loading={loading}
                      icon={<DeleteIcon />}
                    />
                  </div>
                </div>
              )}
            />
          </Table>
        </List>
      </Modal>
    </>
  );
};

export default DoublePointsList;
