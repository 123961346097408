import { Typography } from 'antd';
import React from 'react';
import { supportedTextStyles } from '../../pages/Auth/styles';
import AuthFotterImg from '../../Assets/AuthFotter.png';

export const AuthFotter = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        direction: 'rtl',
      }}
    >
      <Typography style={{ ...supportedTextStyles }}>مدعوم من</Typography>
      <img src={AuthFotterImg} alt="login" />
    </div>
  );
};
