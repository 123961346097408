import React, { useEffect, useState, useRef } from 'react';
import { Text } from '../../text';
import { Badge, Button, Popover, Space } from 'antd';
import { NotificationMessage } from './notification-message';
import { colors } from '../../../Theme/Colors';
import { BellIcon } from '../../../Assets/Svgs';
import { centerFlex } from '../styles';
import { GetAdminNotificationsAction } from '../../../Actions/ConfigActions';
import { NotificationResponse } from '../../../types';
import { LoadingOutlined } from '@ant-design/icons';

// Import statements

export const Notifications: React.FC = () => {
  const [notifications, setNotifications] = useState<NotificationResponse[]>(
    []
  );
  const [loadingContent, setLoadingContent] = useState<boolean>(false);
  const hasMounted = useRef(false);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      const fetchData = async () => {
        try {
          setLoadingContent(true);
          const data = await GetAdminNotificationsAction();
          setNotifications(data as NotificationResponse[]);
        } catch (error) {
          setNotifications([]); // Handle error gracefully
        } finally {
          setLoadingContent(false);
        }
      };
      fetchData();
    }

    return () => {};
  }, []);

  const renderNotifications = () => {
    if (loadingContent) {
      return <LoadingOutlined />;
    } else if (notifications.length > 0) {
      return notifications.map((message) => (
        <Space
          key={message.id}
          style={{
            padding: '8px 0',
            width: '100%',
          }}
        >
          <NotificationMessage message={message.body} />
        </Space>
      ));
    } else {
      return <div>لا يوجد اشعارات</div>;
    }
  };

  const content = (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Text size="sm" textColor={colors.notificationHeader}>
        الاشعارات
      </Text>
      {renderNotifications()}
    </Space>
  );

  return (
    <Popover
      placement="bottomLeft"
      content={content}
      trigger="click"
      overlayStyle={{ width: '16.3125rem' }}
      overlayInnerStyle={{ padding: '8px 12px' }}
      arrow={false}
    >
      <Badge
        count={notifications.length}
        size="small"
        style={{
          direction: 'ltr',
          marginRight: '-13px',
        }}
        color={colors.dangerMain}
      >
        <Button
          shape="default"
          icon={<BellIcon />}
          style={{
            border: 0,
            backgroundColor: colors.sideBarGray,
            direction: 'ltr',
            width: '2.75rem',
            height: '2.5rem',
            ...centerFlex,
          }}
        />
      </Badge>
    </Popover>
  );
};
