import dayjs from 'dayjs';
import { Datum, Params } from '../types';

export const customTitleHandler = ({ resource, action }: Params) => {
  let title = 'Sipes Dashboard';
  if (resource && action) {
    title = `${resource.meta?.label} `;
  }

  return title;
};

const today = new Date();
const startOfWeek = new Date(today);
const endOfLastWeek = new Date(today);
const startOfLastWeek = new Date(endOfLastWeek);
const endOfWeek = new Date(today);
const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
const startOfYear = new Date(today.getFullYear(), 0, 1);
const endOfYear = new Date(today.getFullYear(), 11, 31);
const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
const endOfLastYear = new Date(today.getFullYear() - 1, 11, 31);
const startOfAllTime = new Date(0);
const endOfAllTime = new Date(8640000000000000);
export const predefinedRanges = [
  {
    range: () => ({
      startDate: new Date(),
      endDate: new Date(),
    }),
    isSelected: () => false,
    label: 'Today',
  },
  {
    range: () => ({
      startDate: new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000), // Yesterday
      endDate: new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000),
    }),
    isSelected: () => false,
    label: 'Yesterday',
  },
  {
    range: () => ({
      startDate: startOfWeek,
      endDate: endOfWeek,
    }),
    isSelected: () => false,
    label: 'This Week',
  },
  {
    range: () => ({
      startDate: startOfLastWeek,
      endDate: endOfLastWeek,
    }),
    isSelected: () => false,
    label: 'Last Week',
  },
  {
    range: () => ({
      startDate: startOfMonth,
      endDate: endOfMonth,
    }),
    isSelected: () => false,
    label: 'This Month',
  },
  {
    range: () => ({
      startDate: startOfLastMonth,
      endDate: endOfLastMonth,
    }),
    isSelected: () => false,
    label: 'Last Month',
  },
  {
    range: () => ({
      startDate: startOfYear,
      endDate: endOfYear,
    }),
    isSelected: () => false,
    label: 'This Year',
  },
  {
    range: () => ({
      startDate: startOfLastYear,
      endDate: endOfLastYear,
    }),
    isSelected: () => false,
    label: 'Last Year',
  },
  {
    range: () => ({
      startDate: startOfAllTime,
      endDate: endOfAllTime,
    }),
    isSelected: () => false,
    label: 'All Time',
  },
];

//@ts-ignore
export const formatYAxisTick = (tick) => {
  if (tick >= 1000) {
    return `${tick / 1000}K`;
  }
  return tick;
};

export const formatTime = (time: string) => {
  const [hourMinute, period] = time.split(' ');
  return `${period} ${hourMinute} `;
};
export const convertToArabicNumbers = (
  input: string | number | undefined
): string => {
  // Convert input to string
  const inputString = typeof input === 'string' ? input : String(input);

  const arabicNumbers: Record<string, string> = {
    '0': '٠',
    '1': '١',
    '2': '٢',
    '3': '٣',
    '4': '٤',
    '5': '٥',
    '6': '٦',
    '7': '٧',
    '8': '٨',
    '9': '٩',
  };

  // Replace each English digit with its Arabic counterpart
  let result = inputString.replace(/[0-9]/g, (match) => arabicNumbers[match]);

  // Check if the number is greater than 1000
  const number = parseFloat(inputString);
  if (!isNaN(number) && number >= 1000) {
    // Format the number with commas for thousands separator and Arabic digits
    result = number.toLocaleString('en-US', { useGrouping: true });
  }

  return result;
};

// loadingUtils.ts
export const handleLoading = (
  setLoading: (value: boolean) => void,
  value: boolean
) => {
  setLoading(value);
};

export const toArabicNumerals = (number: number): string => {
  const arabicNumerals = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
  return number
    .toString()
    .replace(/\d/g, (digit) => arabicNumerals[parseInt(digit, 10)]);
};

export const formatDate = (inputDate: string) => {
  const date = new Date(inputDate);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getUTCFullYear();

  return `${day}/${month}/${year}`;
};

export const transformData = (data: Datum[]) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return data?.map(({ monthYear, earnedPoints }) => {
    const [month] = monthYear.split('/');
    const monthName = months[parseInt(month, 10) - 1];
    return { month: monthName, المستهلكين: earnedPoints };
  });
};

export const numberWithCommas = (value: string) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const parseDateString = (dateString: string) => {
  const dateTime = new Date(dateString);
  const day = dateTime.getUTCDate();
  const month = dateTime.getUTCMonth() + 1; // Month is zero-indexed, so add 1
  const year = dateTime.getUTCFullYear();
  return `${day}/${month}/${year}`;
};

export const parseTime = (dateTimeString: string) => {
  const dateTime = new Date(dateTimeString);

  // Convert UTC time to local time zone
  const localHours = dateTime.getHours();
  const localMinutes = dateTime.getMinutes();

  // Format hours to 12-hour format
  let hours = localHours % 12;
  hours = hours || 12; // Handle midnight (0 hours) as 12 AM

  // Determine if it's AM or PM
  const amPm = localHours >= 12 ? 'PM' : 'AM';

  // Format minutes with leading zero if necessary
  const formattedMinutes =
    localMinutes < 10 ? `0${localMinutes}` : localMinutes;

  return `${hours}:${formattedMinutes} ${amPm}`;
};

export const disabledTime = () => {
  const now = dayjs();
  const currentHour = now.hour();
  const currentMinute = now.minute();
  const currentSecond = now.second();

  return {
    disabledHours: () => Array.from({ length: currentHour }, (v, k) => k),
    disabledMinutes: (selectedHour: number) => {
      if (selectedHour === currentHour) {
        return Array.from({ length: currentMinute }, (v, k) => k);
      }
      return [];
    },
    disabledSeconds: (selectedHour: number, selectedMinute: number) => {
      if (selectedHour === currentHour && selectedMinute === currentMinute) {
        return Array.from({ length: currentSecond }, (v, k) => k);
      }
      return [];
    },
  };
};
