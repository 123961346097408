import React from 'react';
import { Form, Input } from 'antd';
import { inputFieldStyles } from '../../pages/Auth/styles';

export const PasswordInput: React.FC = () => {
  return (
    <Form.Item
      name="password"
      rules={[
        {
          required: true,
          message: 'Please enter a password',
        },
        {
          validator: async (_, password) => {
            if (password && password.length < 8) {
              return Promise.reject(
                new Error('Password must be more than 8 characters')
              );
            }

            const regex =
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;
            if (!regex.test(password)) {
              return Promise.reject(
                new Error(
                  'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
                )
              );
            }
          },
        },
      ]}
      noStyle
    >
      <Input
        type="password"
        size="large"
        placeholder="••••••••"
        style={{
          ...inputFieldStyles,
        }}
      />
    </Form.Item>
  );
};
