import React, { useState } from 'react';
import { NotificationResponse } from '../../types';
import { Button, Modal, Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

interface ShowTargetedUserModalProps {
  notification: NotificationResponse;
  onOpen: boolean;
}

export const ScheduledNotificationUsers = (
  props: ShowTargetedUserModalProps
) => {
  const [modalVisible, setModalVisible] = useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };
  const modalTitle = (
    <div>
      <div className="flex flex-row-reverse justify-end items-center">
        <Button type="text" onClick={closeModal} icon={<CloseOutlined />} />
      </div>
    </div>
  );
  return (
    <div>
      <Tag
        color="red"
        className="rounded-full"
        onClick={() => setModalVisible(true)}
      >
        مشاهدة الكل
      </Tag>
      <Modal
        open={modalVisible}
        centered
        width={'31%'}
        title={modalTitle}
        closeIcon={false}
        footer={null}
        styles={{
          content: {
            borderRadius: '24px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          },
        }}
      >
        <div className="flex flex-col  items-center gap-y-4">
          <div className="text-neutral90 text-base font-normal">
            {props.notification.body}
          </div>
          <div className="text-neutral90 text-center  font-medium text-lg">
            تم الارسال الى الارقام التالية
          </div>
          <div>
            <ul className="flex flex-wrap">
              {props.notification.account.map((phoneNumber) => (
                <Tag
                  className="rounded-[30px] bg-tag-background text-neutral90 m-1 p-[4px 12px]"
                  key={phoneNumber.phone_number}
                >
                  {phoneNumber.phone_number}
                </Tag>
              ))}
            </ul>
          </div>
        </div>
      </Modal>
    </div>
  );
};
