import { CSSProperties } from 'react';
import { colors } from '../../Theme/Colors';

export const layoutStyles: CSSProperties = {};

export const containerStyles: CSSProperties = {
  maxWidth: '400px',
  margin: 'auto',
  padding: '32px',
  boxShadow:
    '0px 2px 4px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px rgba(0, 0, 0, 0.03)',
};

export const headStyles: CSSProperties = {
  borderBottom: 0,
  padding: 0,
};

export const bodyStyles: CSSProperties = { padding: 0, marginTop: '32px' };

export const titleStyles: CSSProperties = {
  textAlign: 'center',
  marginBottom: 0,
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 700,
  overflowWrap: 'break-word',
  hyphens: 'manual',
  textOverflow: 'unset',
  whiteSpace: 'pre-wrap',
};

export const checkBoxStyles: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '24px',
};

export const pageContainerStyles: CSSProperties = {
  overflow: 'hidden',
};

export const formContainerStyles: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
};

export const colStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '0px 3rem',
};

export const cardStyles: CSSProperties = {
  border: 0,
  paddingTop: '5.5rem',
};

export const pageTitleStyles: CSSProperties = {
  fontSize: '32px',
  paddingBottom: 45,
  color: colors.black,
};

export const formStyles: CSSProperties = {
  display: 'flex',
  alignContent: 'center',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '2rem',
};

export const inputContainerStyles: CSSProperties = {
  display: 'flex',
  gap: '32px',
  width: '100%',
};

export const inputStyles: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '1rem',
};

export const labelStyles: CSSProperties = {
  color: colors.primaryColor,
  fontSize: '1.125rem',
  fontWeight: 600,
};

export const inputFieldStyles: CSSProperties = {
  backgroundColor: colors.sideBarGray,
  padding: '1rem',
  borderRadius: '0.25rem',
};

export const rememberCheckboxStyles: CSSProperties = {
  color: colors.tableConten, // or use colors.tableConten
  width: 'fit-content',
};

export const submitButtonStyles: CSSProperties = {
  width: '153.5px',
  height: '56px',
  borderRadius: '12px',
  color: 'white',
};

export const supportedTextStyles: CSSProperties = {
  color: colors.gunmetalGray, // or use colors.gunmetalGray
};

export const footerStyles: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  direction: 'rtl',
};

export const subTitleStyles: CSSProperties = {
  color: 'black',
  fontSize: '1rem',
  fontWeight: 400,
  marginBottom: 0,
  marginTop: 0,
  // width: '30rem',
};
