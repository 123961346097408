import React, { useState } from 'react';
import {
  BaseRecord,
  CrudFilters,
  IResourceComponentsProps,
  useNavigation,
} from '@refinedev/core';
import { useTable } from '@refinedev/antd';
import { Button, Popover, Table, Tooltip, message } from 'antd';
import { LineOutlined, RedoOutlined, SearchOutlined } from '@ant-design/icons';
import { ConsumerRes } from '../../types';
import { List } from '@components/crud/consumerList';
import { SearchContent } from '@components/SearchConsumer';
import AddDoublePointsModal from '@components/AddDoublePointsModal';
import DoublePointsList from '@components/DoublePointsList';
import { ColHeaderRight } from '@components/ColHeaderRight';
import { numberWithCommas } from '../../Helpers';
import { apiRequest } from '../../rest-data-provider/utils/APIs';
export const ConsumerList: React.FC<IResourceComponentsProps> = () => {
  const [open, setOpen] = useState(false);
  const [data] = useState<ConsumerRes[]>([]);
  const [usersData, setUsersData] = useState<ConsumerRes[]>([]);
  const [updatedDouplePoints, setUpdatedDouplePoints] = useState<number>(0);
  const {
    searchFormProps,
    tableProps,
    setFilters,
    tableQueryResult: { refetch },
  } = useTable({
    resource: 'account-cruds',
    filters: {
      initial: [{ field: 'account_type', operator: 'eq', value: 'consumer' }],
    },
    onSearch: (values: {
      consumer_id: number;
      consumer_name: string;
      consumer_phone: number;
      consumer_email: string;
      min_price: number;
      max_price: number;
      min_point: number;
      max_point: number;
    }) => {
      const filters: CrudFilters = [];
      if (values.consumer_id) {
        filters.push({
          field: 'id',
          operator: 'eq',
          value: values.consumer_id,
        });
      }
      if (values.consumer_name) {
        filters.push({
          field: 'name',
          operator: 'eq',
          value: values.consumer_name,
        });
      }
      if (values.consumer_phone) {
        filters.push({
          field: 'phone_number',
          operator: 'eq',
          value: values.consumer_phone,
        });
      }
      if (values.consumer_email) {
        filters.push({
          field: 'email',
          operator: 'eq',
          value: values.consumer_email,
        });
      }
      if (values.min_point || values.max_point) {
        filters.push({
          field: 'fromPoints',
          operator: 'eq',
          value: values.min_point,
        });
        filters.push({
          field: 'toPoints',
          operator: 'eq',
          value: values.max_point,
        });
      }
      if (values.min_price || values.max_price) {
        filters.push({
          field: 'fromAmount',
          operator: 'eq',
          value: values.min_price,
        });
        filters.push({
          field: 'toAmount',
          operator: 'eq',
          value: values.max_price,
        });
      }

      return filters;
    },
    syncWithLocation: true,
  });
  const { show } = useNavigation();
  const navigateToShow = (record: BaseRecord) => {
    const consumerRecord = record as ConsumerRes;
    show('account-cruds', consumerRecord.id);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const handleReset = () => {
    searchFormProps.form?.resetFields();
    //@ts-ignore
    setFilters((currentFilters) => {
      // Preserve the user filter and clear other filters
      return currentFilters.filter(
        (filter: { field: string }) => filter.field === 'account_type'
      );
    }, 'replace');
    refetch();
  };
  const getUsers = async () => {
    try {
      const res = await apiRequest<ConsumerRes[]>({
        url: 'account-cruds',
        method: 'GET',
        params: {
          account_type: 'consumer',
          is_double_points: 'true',
        },
      });
      setUsersData(res.data);
    } catch (error) {
      message.error('حدث خطأ ما');
    }
  };
  return (
    <List
      title={false}
      breadcrumb={
        <div className="flex flex-row justify-between">
          <div className="flex flex-row justify-between py-4 px-4">
            <div className="text-primary font-bold text-nowrap text-base leading-8">
              قائمة المستهلكين
            </div>
            <AddDoublePointsModal
              consumers={tableProps.dataSource as ConsumerRes[]}
              data={data}
              updatedDouplePoints={updatedDouplePoints}
              setDoublePoints={setUpdatedDouplePoints}
              getUsers={getUsers}
            />
            <DoublePointsList
              updatedDouplePoints={updatedDouplePoints}
              setUpdatedDouplePoints={setUpdatedDouplePoints}
              usersData={usersData}
              getUsers={getUsers}
            />
          </div>
          <div className="flex">
            <Popover
              arrow={false}
              open={open}
              content={<SearchContent {...searchFormProps} />}
              onOpenChange={handleOpenChange}
              className="w-full"
            >
              <Button className="border-none shadow-none">
                <div className="flex flex-row justify-between text-neutral90 border border-solid rounded-md border-gray-300 bg-white shadow-xs py-4 px-4">
                  <div> ابحث في المستهلكين </div>
                  <div>
                    <SearchOutlined />
                  </div>
                </div>
              </Button>
            </Popover>
            <Tooltip title="إعادة تعيين الفلاتر" trigger={'hover'}>
              <Button
                // icon={}
                onClick={handleReset}
                className="px-2"
              >
                <RedoOutlined />
              </Button>
            </Tooltip>
          </div>
        </div>
      }
    >
      <Table
        {...tableProps}
        rowKey="id"
        className="table w-full mt-12"
        bordered={false}
        pagination={{
          position: ['bottomCenter'],
          nextIcon: <></>,
          prevIcon: <></>,
        }}
        onRow={(record) => {
          return {
            onClick: () => navigateToShow(record),
          };
        }}
      >
        <Table.Column
          className="table-header"
          title={<ColHeaderRight title="الكود" />}
          dataIndex="id"
          render={(value) => <span>{value ? value : <LineOutlined />}</span>}
        />
        <Table.Column
          className="table-header"
          title={<ColHeaderRight title="اسم المستهلك" />}
          dataIndex="name"
          render={(value) => <span>{value ? value : <LineOutlined />}</span>}
        />
        <Table.Column
          className="table-header"
          title="المشتريات"
          dataIndex="WalletBalance"
          render={(value) => (
            <span className="flex justify-center">
              {value ? numberWithCommas(value) : <LineOutlined />}
            </span>
          )}
        />
        <Table.Column
          className="table-header"
          title="النقاط"
          dataIndex={['card_details', 'AllPoints']}
          render={(value) => (
            <span className="flex justify-center">
              {`${numberWithCommas(value)} نقطة`}
            </span>
          )}
        />
        <Table.Column
          className="table-header"
          title={<ColHeaderRight title="رقم الموبايل" />}
          dataIndex="phone_number"
          render={(value) => <span>{value ? value : <LineOutlined />}</span>}
        />
        <Table.Column
          className="table-header ant-table-cell table-header"
          title="الايميل"
          dataIndex="email"
          render={(value) => <span>{value ? value : <LineOutlined />}</span>}
        />
      </Table>
    </List>
  );
};
