import * as React from 'react';
import { useMenuStore } from '../Store/IconsStore';
interface Props {
  selected?: boolean;
}
const OrderIcon = (props: Props) => {
  const { isSelected } = useMenuStore();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      {...props}
    >
      <path
        fill={isSelected === '/contact-us' ? 'white' : '#424242'}
        d="M2.503 5.998 10 9.885l7.497-3.887c-.057-1.021-.873-1.832-1.872-1.832H4.375c-.999 0-1.815.81-1.872 1.832Z"
      />
      <path
        fill={isSelected === '/contact-us' ? 'white' : '#424242'}
        d="M17.5 8.17 10 12.059 2.5 8.17v5.719c0 1.074.84 1.944 1.875 1.944h11.25c1.035 0 1.875-.87 1.875-1.944V8.17Z"
      />
    </svg>
  );
};
export default OrderIcon;
