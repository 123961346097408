import { colors } from '../../../Theme/Colors';
import { Text } from '../../text';

type Props = {
  message?: string;
};

export const NotificationMessage = ({ message }: Props) => {
  if (!message) return <Text>Loading...</Text>;

  return (
    <div>
      <Text
        style={{
          textAlign: 'right',
          fontWeight: '600',
        }}
        textColor={colors.notificationContent}
        size="sm"
      >
        {message || 'Unassigned'}
      </Text>
    </div>
  );
};
