import React, { useEffect, useState } from 'react';
import { Button, Popover } from 'antd';
import { DateRangePicker } from 'react-date-range';
import { Calendear } from '../../Assets/Svgs';
import { datePickerButtonStyles } from './styles';
import { addDays } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './styles.css';
import { predefinedRanges } from '../../Helpers';
import { colors } from '../../Theme/Colors';
import dayjs from 'dayjs';

const months =
  'يناير_فبراير_مارس_أبريل_مايو_يونيو_يوليو_أغسطس_سبتمبر_أكتوبر_نوفمبر_ديسمبر'.split(
    '_'
  );

export const DatePickerPopUp = ({
  onDateChange,
  datesObject,
}: {
  onDateChange: (dates: Date[]) => void;
  datesObject: {
    fromDate: string | null;
    toDate: string | null;
  };
}) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);

  useEffect(() => {
    setState([
      {
        startDate: datesObject.fromDate
          ? dayjs(datesObject.fromDate).toDate()
          : new Date(),
        endDate: datesObject.toDate
          ? dayjs(datesObject.toDate).toDate()
          : addDays(new Date(), 7),
        key: 'selection',
      },
    ]);
  }, [datesObject]);
  const [tempState, setTempState] = useState(state);
  const [visible, setVisible] = useState(false);

  const handleCancel = () => {
    // Reset date range to previous state
    setTempState(state);
    setVisible(false);
  };

  const handleApply = () => {
    // Apply temporary changes to the date range
    setState(tempState);
    setVisible(false);

    if (state.length > 0) {
      const dates = [state[0].startDate, state[0].endDate];
      onDateChange(dates);
    }
  };

  const content = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DateRangePicker
        className="DatePickerStyle"
        onChange={(item: any) => {
          return setState([item.selection]);
        }}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={state}
        direction="horizontal"
        staticRanges={predefinedRanges}
        showDateDisplay={false}
      />
      <div
        style={{
          display: 'flex',
          direction: 'ltr',
          width: '70%',
          justifyContent: 'space-between',
          alignItems: 'center',
          justifyItems: 'center',
          marginRight: '3rem',
          margin: '1rem',
        }}
      >
        <div style={{ display: 'flex' }}>
          <div className="rdrDateDisplayItem">
            {state[0].startDate.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })}
          </div>
          <div style={{ margin: '0.75rem 1rem 0 1rem' }}>{'–'}</div>
          <div className="rdrDateDisplayItem">
            {state[0].endDate.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })}
          </div>
        </div>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Button
            onClick={handleCancel}
            style={{ width: '80px', height: '40px' }}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: colors.blue,
              color: 'white',
              width: '80px',
              height: '40px',
            }}
            onClick={handleApply}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
  return (
    <Popover
      placement="bottomRight"
      content={content}
      trigger="click"
      overlayInnerStyle={{ padding: 0 }}
      overlayStyle={{ zIndex: 999 }}
      style={{ display: 'flex', alignItems: 'center' }}
      arrow={false}
      open={visible}
      onOpenChange={setVisible}
    >
      <Button
        style={{
          ...datePickerButtonStyles,
        }}
      >
        {`${months[dayjs(state[0].startDate).get('month')]} ${dayjs(state[0].startDate).format('YYYY')}` ===
        `${months[dayjs(state[0].endDate).get('month')]} ${dayjs(state[0].endDate).format('YYYY')}`
          ? `${months[dayjs(state[0].startDate).get('month')]} ${dayjs(state[0].startDate).format('YYYY')}`
          : `${months[dayjs(state[0].startDate).get('month')]} ${dayjs(state[0].startDate).format('YYYY')} - ${months[dayjs(state[0].endDate).get('month')]} ${dayjs(state[0].endDate).format('YYYY')}`}
        <Calendear />
      </Button>
    </Popover>
  );
};
