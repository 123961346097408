import React, { CSSProperties, useEffect } from 'react';
import { useThemedLayoutContext } from '@refinedev/antd';
import {
  CanAccess,
  ITreeMenu,
  pickNotDeprecated,
  useLink,
  useMenu,
} from '@refinedev/core';
import {
  BarsOutlined,
  LeftOutlined,
  RightOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Grid, Layout, Menu, theme } from 'antd';
import { ThemedTitleV2 } from './title';
import { colors } from '../../Theme/Colors';
import { useMenuStore } from '../../Store/IconsStore';
const drawerButtonStyles: CSSProperties = {
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  position: 'fixed',
  top: 64,
  zIndex: 999,
};
const { SubMenu } = Menu;
const { useToken } = theme;
export const ThemedSiderV2: React.FC = () => {
  const { setIsSelected } = useMenuStore();

  const { token } = useToken();
  const {
    siderCollapsed,
    setSiderCollapsed,
    mobileSiderOpen,
    setMobileSiderOpen,
  } = useThemedLayoutContext();

  const Link = useLink();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();

  const breakpoint = Grid.useBreakpoint();
  const isMobile =
    typeof breakpoint.lg === 'undefined' ? false : !breakpoint.lg;

  const renderTreeView = (tree: ITreeMenu[], selectedKey?: string) => {
    return tree.map((item: ITreeMenu) => {
      const { label, route, key, name, children, parentName, meta, options } =
        item;
      if (children.length > 0) {
        return (
          <CanAccess
            key={item.key}
            resource={name.toLowerCase()}
            action="list"
            params={{
              resource: item,
            }}
          >
            <SubMenu
              key={item.key}
              icon={meta?.icon ?? <UnorderedListOutlined />}
              title={label}
            >
              {renderTreeView(children, selectedKey)}
            </SubMenu>
          </CanAccess>
        );
      }
      const isSelected = key === selectedKey;

      const isRoute = !(
        pickNotDeprecated(meta?.parent, options?.parent, parentName) !==
          undefined && children.length === 0
      );

      return (
        <CanAccess
          key={item.key}
          resource={name.toLowerCase()}
          action="list"
          params={{
            resource: item,
          }}
        >
          <Menu.Item
            key={item.key}
            icon={meta?.icon ?? (isRoute && <UnorderedListOutlined />)}
            style={{
              backgroundColor: isSelected
                ? colors.primaryColor
                : colors.sideBarGray,
              color: isSelected ? 'white' : colors.tableConten,
              borderRadius: '4px',
              fontSize: '14px',
              fontWeight: '600',
            }}
          >
            <Link to={route ?? ''}>{label}</Link>
            {!siderCollapsed && isSelected && (
              <div className="ant-menu-tree-arrow" />
            )}
          </Menu.Item>
        </CanAccess>
      );
    });
  };

  const items = renderTreeView(menuItems, selectedKey);

  useEffect(() => {
    setIsSelected(selectedKey);
  }, [setIsSelected, selectedKey]);
  const renderSider = () => {
    return <>{items}</>;
  };

  const renderMenu = () => {
    return (
      <Menu
        selectedKeys={selectedKey ? [selectedKey] : []}
        defaultOpenKeys={defaultOpenKeys}
        mode="inline"
        style={{
          padding: '16px 6px',
          border: 'none',
          overflow: 'auto',
          height: 'calc(100% - 72px)',
          background: 'transparent',
        }}
        onClick={() => {
          setMobileSiderOpen(false);
        }}
      >
        {renderSider()}
      </Menu>
    );
  };

  const renderDrawerSider = () => {
    return (
      <>
        <Drawer
          open={mobileSiderOpen}
          onClose={() => setMobileSiderOpen(false)}
          placement="right"
          closable={false}
          width={232}
          bodyStyle={{
            padding: 0,
            backgroundColor: token.colorBgElevated,
          }}
          maskClosable={true}
        >
          <Layout>
            <Layout.Sider
              style={{
                height: '100vh',
                backgroundColor: token.colorBgContainer,
                borderRight: `1px solid ${token.colorBorderBg}`,
              }}
            >
              <div
                style={{
                  width: '232px',
                  padding: '0 16px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  height: '64px',
                  backgroundColor: token.colorBgElevated,
                  borderBottom: 'none',
                }}
              >
                <ThemedTitleV2 collapsed={false} />
              </div>
              {renderMenu()}
            </Layout.Sider>
          </Layout>
        </Drawer>
        <Button
          style={drawerButtonStyles}
          size="large"
          onClick={() => setMobileSiderOpen(true)}
          icon={<BarsOutlined />}
        />
      </>
    );
  };

  if (isMobile) {
    return renderDrawerSider();
  }

  const siderStyles: React.CSSProperties = {
    backgroundColor: token.colorBgContainer,
    borderRight: `1px solid ${token.colorBorderBg}`,
    position: 'sticky',
    top: 0,
    left: 0,
    height: '100vh',
    zIndex: 999,
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.12)',
  };

  return (
    <>
      <Layout.Sider
        style={siderStyles}
        width={232}
        collapsible
        collapsed={siderCollapsed}
        onCollapse={(collapsed, type) => {
          if (type === 'clickTrigger') {
            setSiderCollapsed(collapsed);
          }
        }}
        collapsedWidth={80}
        breakpoint="lg"
        trigger={
          <Button
            type="text"
            style={{
              borderRadius: 0,
              height: '100%',
              width: '100%',
              backgroundColor: token.colorBgElevated,
              borderRight: `1px solid ${token.colorBorderBg}`,
            }}
          >
            {siderCollapsed ? (
              <LeftOutlined
                style={{
                  color: token.colorPrimary,
                }}
              />
            ) : (
              <RightOutlined
                style={{
                  color: token.colorPrimary,
                }}
              />
            )}
          </Button>
        }
      >
        <div
          style={{
            width: siderCollapsed ? '100%' : '100%',
            padding: siderCollapsed ? '0' : '0 50px 4px 0',
            display: 'flex',
            justifyContent: siderCollapsed ? 'center' : 'flex-start',
            alignItems: 'center',
            backgroundColor: token.colorBgElevated,
            fontSize: '14px',
            boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.12)',
            minHeight: '98px',
          }}
        >
          <ThemedTitleV2 collapsed={siderCollapsed} />
        </div>
        {renderMenu()}
      </Layout.Sider>
    </>
  );
};
