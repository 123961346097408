import React from 'react';

import { Layout, Space } from 'antd';

import { CurrentUser } from './HeaderComponent/currentUser';
import { Notifications } from './HeaderComponent/notifications';
import { colors } from '../../Theme/Colors';

export const Header: React.FC = () => {
  const headerStyles: React.CSSProperties = {
    backgroundColor: colors.white,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '8px 48px',
    height: '98px',
    boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.12)',
  };

  return (
    <Layout.Header style={headerStyles}>
      <Space direction="horizontal" size="middle" style={{ lineHeight: 0 }}>
        <Notifications />
        <CurrentUser />
      </Space>
    </Layout.Header>
  );
};
