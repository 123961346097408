import * as React from 'react';
const UserIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#424242"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.333 5.833a3.333 3.333 0 1 1-6.666 0 3.333 3.333 0 0 1 6.666 0ZM10 11.667A5.833 5.833 0 0 0 4.167 17.5h11.666A5.833 5.833 0 0 0 10 11.667Z"
    />
  </svg>
);
export default UserIcon;
