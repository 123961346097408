import React, { useState } from 'react';
import {
  ForgotPasswordPageProps,
  useRouterType,
  useLink,
  useRouterContext,
  useNavigation,
} from '@refinedev/core';
import {
  colStyle,
  formContainerStyles,
  pageContainerStyles,
  cardStyles,
  pageTitleStyles,
  submitButtonStyles,
  inputFieldStyles,
  labelStyles,
  inputStyles,
  subTitleStyles,
  inputContainerStyles,
} from './styles';
import {
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Button,
  LayoutProps,
  CardProps,
  FormProps,
  theme,
} from 'antd';
import { PasswordInput } from '../../components/passwordfield';
import { LogInMainImage } from '../../components/LogInMainImage';
import { AuthFotter } from '../../components/poweredBy';
import { ResetPasswordAction } from '../../Actions/AuthActions';
import { colors } from '../../Theme/Colors';
type ResetPasswordPageProps = ForgotPasswordPageProps<
  LayoutProps,
  CardProps,
  FormProps
>;

/**
 * **refine** has forgot password page form which is served on `/forgot-password` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/antd-auth-page/#forgot-password} for more details.
 */
export const ResetPasswordPage: React.FC<ResetPasswordPageProps> = ({
  loginLink,
  contentProps,
  formProps,
}) => {
  const { token } = theme.useToken();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();

  const ActiveLink = routerType === 'legacy' ? LegacyLink : Link;
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passStatus, setPassStatus] = useState(false);
  const { push } = useNavigation();
  const handleDisableButton = (value: boolean) => {
    setDisabled(value);
  };
  const handleLoading = (value: boolean) => {
    setLoading(value);
  };
  const navigateToLogIn = (status: boolean) => {
    if (status === true) {
      push('/login');
    } else {
      setPassStatus(true);
    }
  };
  return (
    <div style={{ ...pageContainerStyles }}>
      <Row
        wrap={false}
        style={{
          ...formContainerStyles,
        }}
      >
        <Col
          span={14}
          style={{
            ...colStyle,
          }}
        >
          <Card
            style={{
              ...cardStyles,
            }}
            {...(contentProps ?? {})}
            styles={{ body: { padding: '0px 0px' } }}
          >
            <Typography.Title level={3} style={{ ...pageTitleStyles }}>
              أهلًا بك في برنامج برنامج نقاط سايبس
            </Typography.Title>
            <Form
              layout="vertical"
              // Need for Integration
              onFinish={(values) => {
                ResetPasswordAction(
                  values.password,
                  navigateToLogIn,
                  handleLoading
                );
              }}
              requiredMark={false}
              {...formProps}
              style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}
            >
              <Typography.Title
                style={{
                  color: 'black',
                  fontSize: '1.5rem',
                  fontWeight: 600,
                  marginBottom: 0,
                }}
              >
                {'إعادة تعيين كلمة السر'}
              </Typography.Title>
              <Typography.Title style={{ ...subTitleStyles }}>
                أعد تعيين كلمة سر جديدة
              </Typography.Title>
              <div style={{ ...inputContainerStyles }}>
                <div style={{ ...inputStyles }}>
                  <Typography.Text
                    style={{
                      ...labelStyles,
                    }}
                  >
                    كلمة السر الجديدة
                  </Typography.Text>
                  <PasswordInput />
                </div>
                <div style={{ ...inputStyles }}>
                  <Typography.Text
                    style={{
                      ...labelStyles,
                    }}
                  >
                    تأكيد كلمة السر
                  </Typography.Text>
                  <Form.Item
                    name="confirmPassword"
                    dependencies={['password']}
                    rules={[
                      {
                        required: true,
                        message: 'يرجى تأكيد كلمة المرور',
                      },
                      {
                        pattern:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+-/|])[A-Za-z\d!@#$%^&*()_+-/|]/,
                        message:
                          'كلمة المرور يجب أن تتضمن على الأقل حرف كبير، حرف صغير، رقم، وحرف خاص.',
                      },
                      {
                        min: 8,
                        message: 'الرقم يجب أن يكون على الأقل 8 حروف',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            handleDisableButton(false);
                            return Promise.resolve();
                          }
                          handleDisableButton(true);
                          return Promise.reject(
                            new Error('كلمات المرور غير متطابقة')
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      type="password"
                      size="large"
                      placeholder="••••••••"
                      style={{
                        ...inputFieldStyles,
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                style={{
                  marginBottom: 0,
                }}
              >
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                  style={{
                    ...submitButtonStyles,
                    marginBottom: '0px',
                    marginTop: '0px',
                  }}
                  disabled={disabled}
                  loading={loading}
                >
                  أعد تعيين
                </Button>
              </Form.Item>
              {passStatus && (
                <Typography.Text style={{ color: colors.danger }}>
                  تم آستخدام كلمة المرور هذه من قبل، الرجاء أختيار كلمة مرور
                  جديدة
                </Typography.Text>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {loginLink ?? (
                  <Typography.Text
                    style={{
                      fontSize: 12,
                      marginLeft: 'auto',
                    }}
                  >
                    <ActiveLink
                      style={{
                        fontWeight: 'bold',
                        color: token.colorPrimaryTextHover,
                      }}
                      to="/login"
                    >
                      تسجيل الدخول
                    </ActiveLink>
                  </Typography.Text>
                )}
              </div>
            </Form>
          </Card>
          <AuthFotter />
        </Col>
        <LogInMainImage />
      </Row>
    </div>
  );
};
