import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { Table, Typography } from 'antd';
import { List, useTable } from '@refinedev/antd';
import './../styles.css';
import CreateNotificationModal from '@components/CreateNotificationModal';
import { ColHeaderRight } from '@components/ColHeaderRight';
import { parseDateString, parseTime } from '../../Helpers';
import { ScheduledNotificationUsers } from '@components/ShowTargetedUserModal';
import { NotificationResponse } from '../../types';
export const NotificationList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    tableQueryResult: { refetch },
  } = useTable({
    syncWithLocation: true,
  });

  return (
    <>
      <List
        title={
          <div className="flex gap-6 ">
            <Typography.Title level={4}>قائمة المستهلكين</Typography.Title>

            <CreateNotificationModal refetch={refetch} />
          </div>
        }
      >
        <Table
          {...tableProps}
          rowKey="id"
          className="table"
          bordered={false}
          pagination={{
            position: ['bottomCenter'],
          }}
        >
          <Table.Column
            className="table-header"
            title={<ColHeaderRight title="الى" />}
            dataIndex="is_for_all"
            width={200}
            render={(value, record: NotificationResponse) => (
              <span className="table-cell">
                {value ? (
                  'الجميع'
                ) : (
                  <ScheduledNotificationUsers notification={record} onOpen />
                )}
              </span>
            )}
          />
          <Table.Column
            className="table-header"
            title={<ColHeaderRight title="العنوان" />}
            dataIndex="title"
            width={200}
            render={(value) => <span>{value}</span>}
          />
          <Table.Column
            className="table-header"
            title={<ColHeaderRight title="الاشعار" />}
            dataIndex="body"
            width={230}
            render={(value) => <span>{value}</span>}
          />
          <Table.Column
            className="table-header"
            title="الحالة"
            dataIndex="is_schedule"
            width={150}
            render={(value) => (
              <span className="flex justify-center">
                {value ? 'مجدول' : 'تم الارسال'}
              </span>
            )}
          />
          <Table.Column
            className="table-header"
            title="التاريخ"
            dataIndex="schedule_date"
            width={150}
            render={(value) => (
              <span className="flex justify-center">
                {parseDateString(value)}
              </span>
            )}
          />
          <Table.Column
            className="table-header ant-table-cell table-header"
            title="الوقت"
            dataIndex="schedule_date"
            width={150}
            render={(value) => (
              <span
                className="flex justify-center "
                style={{ direction: 'ltr' }}
              >
                {parseTime(value)}
              </span>
            )}
          />
        </Table>
      </List>
    </>
  );
};
