import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { useTable, List } from '@refinedev/antd';
import { Table } from 'antd';
import './../styles.css';
import { ColHeaderRight } from '@components/ColHeaderRight';
export const ContactUsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  return (
    <List title="قائمة الاتصال">
      <Table
        {...tableProps}
        rowKey="id"
        bordered={false}
        className="table"
        pagination={{
          position: ['bottomCenter'],
        }}
      >
        <Table.Column
          className="table-header"
          dataIndex="name"
          title={<ColHeaderRight title="الاسم" />}
          render={(value) => <span className="table-cell">{value}</span>}
          width={200}
        />
        <Table.Column
          className="table-header"
          dataIndex="phone_number"
          title={<ColHeaderRight title="رقم الموبايل" />}
          render={(value) => <span className="table-cell">{value}</span>}
          width={200}
        />
        <Table.Column
          className="table-header"
          dataIndex="message"
          title={<ColHeaderRight title="الرسالة" />}
          render={(value) => (
            <span className="table-cell w-[100%]">{value}</span>
          )}
        />
      </Table>
    </List>
  );
};
