import { CSSProperties } from 'react';
import { centerFlex } from '../layout/styles';

export const datePickerButtonStyles: CSSProperties = {
  ...centerFlex,
  borderRadius: '0.5rem',
  border: '1px solid #D0D5DD',
  gap: '0.375rem',
  color: '#667085',
  padding: '0.75rem 1.125rem',
  fontSize: '1rem',
  fontWeight: 600,
  lineHeight: '1.5rem',
  fontStyle: 'normal',
  width: '14.5rem',
  height: '3rem',
};
