import React from 'react';
import { Dayjs } from 'dayjs';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { monthsArabic } from '../../Constants';
import { toArabicNumerals } from '../../Helpers';

interface CalendarHeaderProps {
  value: Dayjs;
  onChange: (value: Dayjs) => void;
}

const CalendarHeader: React.FC<CalendarHeaderProps> = ({ value, onChange }) => {
  const monthIndex = value.month();
  const month = monthsArabic[monthIndex];
  const year = value.year();

  const handlePrevMonth = () => {
    onChange(value.clone().subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    onChange(value.clone().add(1, 'month'));
  };

  return (
    <div className="flex flex-col gap-3 my-3">
      <div className="flex justify-between custom-calendar-header">
        <Button
          className="border-none shadow-none"
          onClick={handleNextMonth}
          icon={<RightOutlined />}
        />
        <div className="month-year">{`${month} ${year}`}</div>
        <Button
          className="border-none shadow-none"
          onClick={handlePrevMonth}
          icon={<LeftOutlined />}
        />
      </div>
      <div className="flex justify-between gap-4">
        <div className="text-gunmetal-gray font-semibold rounded-md border border-solid border-gray-300 bg-white shadow-xs py-2.5 px-3.5">
          اليوم
        </div>
        <div className="w-full text-textPrimary900  rounded-md border border-solid border-gray-300 bg-white shadow-xs py-2 px-3.5">
          {`${toArabicNumerals(value.date())} ${monthsArabic[value.month()]} ${toArabicNumerals(year)}`}
        </div>
      </div>
    </div>
  );
};

export default CalendarHeader;
