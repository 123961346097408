import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  // Legend,
} from 'recharts';
import './styles.css';
import { colors } from '../../Theme/Colors';
import { formatYAxisTick, transformData } from '../../Helpers';
import { Datum } from '../../types';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
  data: Datum[];
}

const PointsLineChart: React.FC<Props> = (props) => {
  const lineChartData = transformData(props.data);
  return (
    <div style={{ width: '100%', maxWidth: '1000px', margin: '0 auto' }}>
      {props.data ? (
        <LineChart
          width={1050}
          height={347}
          data={lineChartData}
          margin={{ top: 0, right: 0, left: 0, bottom: 20 }}
        >
          <Legend
            aria-label="points"
            align="right"
            display="flex"
            verticalAlign="top"
            iconType="circle"
            iconSize={8}
          />
          <CartesianGrid
            vertical={false}
            horizontal={true}
            strokeDasharray=""
            color="#f2f4f7"
          />
          <XAxis
            dataKey="month"
            label={{ value: 'الشهر', position: 'bottom' }}
            className="custom-x-axis"
            axisLine={{ strokeWidth: 10 }}
            tickMargin={10}
            style={{ marginRight: '100px' }}
          />
          <YAxis
            label={{ value: 'النقاط', angle: -90, position: 'insideLeft' }}
            className="custom-x-axis"
            tickFormatter={formatYAxisTick}
          />

          <Tooltip />
          <Line
            type="natural"
            dataKey="المستهلكين"
            stroke={colors.blue}
            dot={false}
          />
        </LineChart>
      ) : (
        <LoadingOutlined />
      )}
    </div>
  );
};

export default PointsLineChart;
