import { HomeData, NotificationResponse } from 'src/types';
import {
  apiRequestGet,
  apiRequestPost,
} from '../rest-data-provider/utils/APIs';
import dayjs from 'dayjs';
import { message } from 'antd';

export const AddDoublePointsAction = async (
  ids?: string[],
  getUsers?: () => void,
  handleLoading?: (value: boolean) => void
) => {
  handleLoading && handleLoading(true);
  try {
    await apiRequestPost<boolean>({
      url: '/account-cruds/double-Points',
      data: { ids: ids },
    });
    handleLoading && handleLoading(false);
    getUsers && getUsers();
  } catch (error) {
    handleLoading && handleLoading(false);
  }
};

export const GetGraphDataAction = async (
  fromDate?: string,
  toDate?: string
) => {
  try {
    const data = await apiRequestGet<HomeData>({
      url: '/account-cruds/graph-data',
      params: { fromDate: fromDate, toDate: toDate },
    });
    return data.data;
  } catch (error) {
    return error;
  }
};
export const RemoveDoublePointsAction = async (
  id: string,
  handleFinish: (value: boolean, state: boolean) => void,
  handleRefetch: () => void
) => {
  handleFinish(true, false);
  try {
    await apiRequestPost<boolean>({
      url: '/account-cruds/remove-double',
      data: { id: id },
    });

    handleFinish(false, true);
    handleRefetch();
    message.success('تم الحذف بنجاح');
  } catch (error) {
    handleFinish(false, false);
    message.error('حدث خطأ ما');
  }
};

export const CreateNotificationAction = async (
  norificationData: {
    accounts_ids?: number[];
    is_for_all: boolean;
    date?: dayjs.Dayjs;
    title: string;
    body: string;
  }
  // handleFinish: (value: boolean, state: boolean) => void
) => {
  // handleFinish(true, false);

  try {
    await apiRequestPost<boolean>({
      url: '/notification-cruds/scheduling-notification',
      data: norificationData,
    });

    // handleFinish(false, true);
    message.success('تم آنشاء الاشعار بنجاح');
  } catch (error) {
    // handleFinish(false, false);
    message.error('حدث خطأ ما');
  }
};

export const GetAdminNotificationsAction = async (
  handleLoading?: (value: boolean) => void
) => {
  handleLoading && handleLoading(true);
  try {
    const data = await apiRequestGet<NotificationResponse>({
      url: '/notification-cruds/unread-notifications',
    });
    handleLoading && handleLoading(false);
    return data.data;
  } catch (error) {
    handleLoading && handleLoading(false);
    return error;
  }
};
