import * as React from 'react';
import { useMenuStore } from '../Store/IconsStore';

interface Props {
  selected?: boolean;
}

const BellSideBarIcon = (props: Props) => {
  const { isSelected } = useMenuStore();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      {...props}
    >
      <path
        fill={isSelected === '/notification-cruds' ? 'white' : '#424242'}
        d="M11.863 15.833c.72 0 1.101.854.62 1.39A3.325 3.325 0 0 1 10 18.333a3.326 3.326 0 0 1-2.483-1.11.833.833 0 0 1 .528-1.384l.092-.005h3.726ZM10 1.667c1.132 0 2.088.752 2.396 1.784l.038.142.007.036a6.678 6.678 0 0 1 3.353 5.058l.024.239.015.24v2.443l.018.114a2.5 2.5 0 0 0 .952 1.539l.14.097.134.083c.717.406.467 1.471-.314 1.553l-.096.005H3.333c-.856 0-1.155-1.137-.41-1.558a2.5 2.5 0 0 0 1.226-1.72l.018-.119V9.128A6.667 6.667 0 0 1 7.353 3.75l.206-.122.009-.035a2.502 2.502 0 0 1 2.135-1.909l.151-.014.147-.003Z"
      />
    </svg>
  );
};
export default BellSideBarIcon;
