import * as React from 'react';
import { useMenuStore } from '../Store/IconsStore';

interface Props {
  selected?: boolean;
}

const ConsumerIcon = (props: Props) => {
  const { isSelected } = useMenuStore();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      {...props}
    >
      <path
        fill={isSelected === '/account-cruds' ? 'white' : '#424242'}
        d="M10 2.501A7.502 7.502 0 0 0 3.294 13.36 7.5 7.5 0 1 0 10 2.502Zm4.711 12.46v-.103a4.717 4.717 0 0 0-4.71-4.711 4.712 4.712 0 0 0-4.712 4.711v.102A6.79 6.79 0 0 1 3.159 10 6.849 6.849 0 0 1 10 3.16a6.849 6.849 0 0 1 6.29 9.542 6.788 6.788 0 0 1-1.579 2.26Z"
      />
      <path
        fill={isSelected === '/account-cruds' ? 'white' : '#424242'}
        d="M10.015 4.71a2.538 2.538 0 1 0 .01 5.075 2.538 2.538 0 0 0-.01-5.075Z"
      />
    </svg>
  );
};
export default ConsumerIcon;
