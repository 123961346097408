import React, { ReactNode } from 'react';
import { colors } from '../../Theme/Colors';

type Props = {
  value?: string | ReactNode;
  percent?: boolean;
  title: string;
};

export const DashBoardCard = (props: Props) => {
  return (
    <div
      style={{
        backgroundColor: '#F6F8FB',
        padding: '1.5rem',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        width: '49%',
        borderRadius: '1rem',
      }}
    >
      <div
        style={{
          fontSize: '3rem',
          fontWeight: 700,
          lineHeight: '3.875rem',
          fontStyle: 'normal',
          color: colors.primaryColor,
        }}
      >
        {props.percent ? '٪' : ''}
        {props.value}
      </div>
      <div
        style={{
          fontSize: '1.5rem',
          fontWeight: 600,
          lineHeight: '2rem',
          fontStyle: 'normal',
          color: colors.primaryColor,
        }}
      >
        {props.title}
      </div>
    </div>
  );
};
