import React, { useState } from 'react';
import { IResourceComponentsProps, useShow } from '@refinedev/core';
import { Button, Table } from 'antd';
import '../styles.css';
import { Show } from '../../components/crud/show';
import { LineOutlined } from '@ant-design/icons';
import { ColHeaderRight } from '@components/ColHeaderRight';
import { formatDate, toArabicNumerals } from '../../Helpers';

export const ConsumerShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const [activeTable, setActiveTable] = useState('purchases');

  const handleTableChange = (table: string) => {
    setActiveTable(table);
  };
  const record = data?.data;

  return (
    <Show isLoading={isLoading} title={record?.name} consumerId={record?.id}>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => handleTableChange('purchases')}
            className="tabsBtn"
            style={{
              borderBottom:
                activeTable === 'purchases'
                  ? '2px solid red'
                  : '2px solid #E1E1E1',
              backgroundColor:
                activeTable === 'purchases' ? '#F8F8F8' : 'transparent',
            }}
          >
            سجل المشتريات
          </Button>
          <Button
            onClick={() => handleTableChange('points')}
            className="tabsBtn"
            style={{
              borderBottom:
                activeTable === 'points'
                  ? '2px solid red'
                  : '2px solid #E1E1E1',
              backgroundColor:
                activeTable === 'points' ? '#F8F8F8' : 'transparent',
              marginRight: '0px',
            }}
          >
            سجل النقاط
          </Button>
        </div>
        {activeTable === 'purchases' ? (
          <Table
            dataSource={record?.redeemed_transactions}
            rowKey="id"
            className="table w-full mt-5"
            bordered={false}
            pagination={{
              position: ['bottomCenter'],
              nextIcon: <></>,
              prevIcon: <></>,
            }}
          >
            <Table.Column
              className="table-header"
              title={<ColHeaderRight title="رقم العملية" />}
              dataIndex="id"
              render={(value) => (
                <span>{value ? value : <LineOutlined />}</span>
              )}
            />

            <Table.Column
              className="table-header"
              title={<ColHeaderRight title="التاريخ" />}
              dataIndex="trx_date"
              render={(value) => (
                <span>{value ? formatDate(value) : <LineOutlined />}</span>
              )}
            />
            <Table.Column
              className="table-header"
              title={<ColHeaderRight title="القيمة ج.م" />}
              dataIndex={'amount'}
              render={(value) => <span> {value} </span>}
            />
          </Table>
        ) : (
          <Table
            dataSource={record?.rewarding_transactions}
            rowKey="id"
            className="table w-full mt-5"
            bordered={false}
            pagination={{
              position: ['bottomCenter'],
              nextIcon: <></>,
              prevIcon: <></>,
            }}
          >
            <Table.Column
              className="table-header"
              title={<ColHeaderRight title="نوع العملية" />}
              dataIndex="epoints_type"
              render={(value) => (
                <span>
                  {value === 'reward' ? (
                    'نقاط مكتسبة '
                  ) : value === 'redeem' ? (
                    'استبدال'
                  ) : (
                    <LineOutlined />
                  )}
                </span>
              )}
            />
            <Table.Column
              className="table-header"
              title={<ColHeaderRight title="المتجر" />}
              dataIndex="merchant_name"
              render={(value) => (
                <span>{value ? value : <LineOutlined />}</span>
              )}
            />
            <Table.Column
              className="table-header"
              title={<ColHeaderRight title="التاريخ" />}
              dataIndex="trx_date"
              render={(value) => (
                <span>{value ? formatDate(value) : <LineOutlined />}</span>
              )}
            />
            <Table.Column
              className="table-header"
              title={<ColHeaderRight title="النقاط" />}
              dataIndex={'points'}
              render={(value) => (
                <span>
                  {value ? toArabicNumerals(value) : <LineOutlined />}
                </span>
              )}
            />
            <Table.Column
              className="table-header"
              title={<ColHeaderRight title="القيمة ج.م" />}
              dataIndex="amount"
              render={(value) => (
                <span>
                  {value ? toArabicNumerals(value) : <LineOutlined />}
                </span>
              )}
            />
          </Table>
        )}
      </div>
    </Show>
  );
};
