export const colors = {
  primaryColor: '#FF0006',
  secondaryColor: '#F09B29',
  dangerMain: '#E00000',
  white: '#FFFFFF',
  black: '#000000',
  danger: '#EE0000',
  neutral50: '#C2C2C2',
  sideBarGray: '#F5F5F5',
  tableHeader: '#0A0A0A',
  tableConten: '#424242',
  agreeColor: '#0E994B',
  notificationHeader: '#757575',
  notificationContent: '#424242',
  gunmetalGray: '#344054',
  disableGrey: '#B0B0B0',
  blue: '#0376C6',
};
