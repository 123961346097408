import React, { useState } from 'react';
import {
  ForgotPasswordPageProps,
  ForgotPasswordFormTypes,
  useRouterType,
  useLink,
  useTranslate,
  useRouterContext,
  useNavigation,
} from '@refinedev/core';
import {
  colStyle,
  formContainerStyles,
  pageContainerStyles,
  cardStyles,
  pageTitleStyles,
  submitButtonStyles,
  inputFieldStyles,
  labelStyles,
  inputStyles,
  subTitleStyles,
} from './styles';
import {
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Button,
  LayoutProps,
  CardProps,
  FormProps,
  theme,
} from 'antd';
import validator from 'email-validator';
import { LogInMainImage } from '../../components/LogInMainImage';
import { AuthFotter } from '../../components/poweredBy';
import { colors } from '../../Theme/Colors';
import { ForgotPasswordAction } from '../../Actions/AuthActions';
import { ExclamationCircleOutlined } from '@ant-design/icons';

type ResetPassworProps = ForgotPasswordPageProps<
  LayoutProps,
  CardProps,
  FormProps
>;

/**
 * **refine** has forgot password page form which is served on `/forgot-password` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/antd-auth-page/#forgot-password} for more details.
 */
export const ForgotPasswordPage: React.FC<ResetPassworProps> = ({
  loginLink,
  contentProps,
  formProps,
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm<ForgotPasswordFormTypes>();
  const translate = useTranslate();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();

  const ActiveLink = routerType === 'legacy' ? LegacyLink : Link;

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [email, setEmail] = useState('');
  const [validUser, setValidUser] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
    setIsValidEmail(validator.validate(value));
  };
  const { push } = useNavigation();
  const validEmail = (value?: boolean) => {
    if (value) {
      setValidUser(true);
    } else {
      push('/verification');
    }
  };
  const handleLoading = (value: boolean) => {
    setLoading(value);
  };
  return (
    <div style={{ ...pageContainerStyles }}>
      <Row
        wrap={false}
        style={{
          ...formContainerStyles,
        }}
      >
        <Col
          span={14}
          style={{
            ...colStyle,
          }}
        >
          <Card
            style={{
              ...cardStyles,
            }}
            {...(contentProps ?? {})}
            styles={{ body: { padding: '0px 0px' } }}
          >
            <Typography.Title level={3} style={{ ...pageTitleStyles }}>
              أهلًا بك في برنامج برنامج نقاط سايبس
            </Typography.Title>
            <Form
              layout="vertical"
              form={form}
              onFinish={(values) =>
                ForgotPasswordAction(handleLoading, values, validEmail)
              }
              requiredMark={false}
              {...formProps}
              style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}
            >
              <Typography.Title
                style={{
                  color: 'black',
                  fontSize: '1.5rem',
                  fontWeight: 600,
                  marginBottom: 0,
                }}
              >
                {'إعادة تعيين كلمة السر'}
              </Typography.Title>

              <Typography.Title style={{ ...subTitleStyles }}>
                من فضلك قم بإدخال الإيميل الخاص بمدير الموقع لإعادة تعيين كلمة
                السر.
                <br />
                سوف نقوم بإرسال كود متغير
              </Typography.Title>
              <div style={{ ...inputStyles }}>
                <label
                  style={{
                    ...labelStyles,
                  }}
                >
                  {translate('pages.login.fields.email', 'الإيميل')}
                </label>
                <Form.Item name="email" noStyle>
                  <Input
                    type="email"
                    size="large"
                    placeholder={translate(
                      'pages.forgotPassword.fields.email',
                      'الإيميل'
                    )}
                    value={email}
                    onChange={handleEmailChange}
                    style={{
                      ...inputFieldStyles,
                      width: '50%',
                      borderColor: validUser ? colors.dangerMain : '',
                    }}
                    suffix={
                      validUser ? (
                        <ExclamationCircleOutlined
                          style={{ color: colors.danger }}
                        />
                      ) : null
                    }
                  />
                </Form.Item>
                {validUser && (
                  <Typography.Text style={{ color: colors.danger }}>
                    خطأ في الإيميل
                  </Typography.Text>
                )}
              </div>

              <Form.Item
                style={{
                  marginTop: '24px',
                  marginBottom: 0,
                }}
              >
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                  style={{
                    ...submitButtonStyles,
                    marginBottom: '0px',
                    marginTop: '0px',
                  }}
                  disabled={!isValidEmail}
                  loading={loading}
                >
                  {translate('pages.forgotPassword.buttons.submit', 'إرسال')}
                </Button>
              </Form.Item>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {loginLink ?? (
                  <Typography.Text
                    style={{
                      fontSize: 12,
                      marginLeft: 'auto',
                    }}
                  >
                    <ActiveLink
                      style={{
                        fontWeight: 'bold',
                        color: token.colorPrimaryTextHover,
                      }}
                      to="/login"
                    >
                      {translate('pages.login.signin', 'تسجيل الدخول')}
                    </ActiveLink>
                  </Typography.Text>
                )}
              </div>
            </Form>
          </Card>
          <AuthFotter />
        </Col>
        <LogInMainImage />
      </Row>
    </div>
  );
};
