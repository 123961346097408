import type { IResourceItem } from '@refinedev/core';
import {
  BellSideBarIcon,
  ConsumerIcon,
  DashboardIcon,
  OrderIcon,
} from '../Assets/Svgs';

export const resources: IResourceItem[] = [
  {
    name: 'account-cruds/graph-data',
    list: '/',
    meta: {
      label: 'نظرة عامة',
      icon: <DashboardIcon />,
    },
  },
  // Need for future use
  // {
  //   name: 'trader_list',
  //   list: '/trader-list',
  //   create: '/trader-list/create',
  //   show: '/blog-posts/show/:id',
  //   meta: {
  //     label: 'قائمة التجار',
  //     canCreate: true,
  //     canEdit: false,
  //     canDelete: false,
  //     canShow: true,
  //     icon: <TraderIcon />,
  //   },
  // },
  {
    name: 'account-cruds',
    list: '/consumer-list',
    show: '/consumer-list/show/:id',
    meta: {
      label: 'قائمة المستهلكين',
      canCreate: true,
      canEdit: false,
      canDelete: false,
      canShow: true,
      icon: <ConsumerIcon />,
    },
  },
  {
    name: 'notification-cruds',
    list: '/notification-management',
    meta: {
      label: 'ادارة الاشعارات',
      canCreate: true,
      canEdit: false,
      canDelete: false,
      canShow: true,
      icon: <BellSideBarIcon />,
    },
  },
  {
    name: 'contact-us',
    list: '/contact-us',
    meta: {
      label: 'طلبات التواصل',
      canCreate: true,
      canEdit: false,
      canDelete: false,
      canShow: true,
      icon: <OrderIcon />,
    },
  },
  // { // To be removed after integration
  //   name: 'blog_posts',
  //   list: '/blog-posts',
  //   create: '/blog-posts/create',
  //   edit: '/blog-posts/edit/:id',
  //   show: '/blog-posts/show/:id',
  //   meta: {
  //     canDelete: true,
  //   },
  // },
];
