import { SearchOutlined } from '@ant-design/icons';
import { SaveButton } from '@refinedev/antd';
import { Button, Col, Form, Input, Row } from 'antd';
import { FormProps } from 'antd/lib';
import React from 'react';

// interface SearchProps extends FormProps {
//   onSearch: (value: boolean) => void;
// }

export const SearchContent: React.FC<FormProps> = (props) => {
  const [form] = Form.useForm();

  const handleSearch = () => {
    {
      // Perform search operation
      props.form?.submit();
      // props.onSearch(false);
      // After search
      form.resetFields();
    }
  };
  return (
    <Form {...props} style={{ padding: '1rem' }}>
      <Col style={{ gap: '1rem' }} className="text-neutral90">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '1rem',
          }}
        >
          <div> ابحث في المستهلكين</div>
          <div>
            <SearchOutlined />
          </div>
        </div>
        <Row style={{ gap: '1rem' }}>
          <Form.Item name="consumer_name">
            <Input
              placeholder="الاسم"
              type="text"
              size="large"
              style={{
                width: '15.687rem',
                backgroundColor: '#F5F5F5',
              }}
            />
          </Form.Item>
          <Form.Item name="consumer_id">
            <Input
              placeholder="الكود"
              size="large"
              type="number"
              style={{
                width: '15.687rem',
                backgroundColor: '#F5F5F5',
              }}
            />
          </Form.Item>
        </Row>
        <Row style={{ gap: '1rem' }}>
          <Form.Item name="consumer_phone">
            <Input
              placeholder="رقم التليفون"
              size="large"
              type="number"
              style={{
                width: '15.687rem',
                backgroundColor: '#F5F5F5',
              }}
            />
          </Form.Item>
          <Form.Item name="consumer_email">
            <Input
              placeholder="الايميل"
              size="large"
              type="email"
              style={{
                width: '15.687rem',
                backgroundColor: '#F5F5F5',
              }}
            />
          </Form.Item>
        </Row>
        <div style={{ paddingBottom: '0.75rem' }}>المشتريات</div>
        <Row style={{ gap: '1rem' }}>
          <span style={{ paddingTop: '0.5rem' }}>من</span>
          <Form.Item name="min_price">
            <Input
              placeholder="٠ ج.م"
              size="large"
              type="number"
              style={{
                width: '13.812rem',
                backgroundColor: '#F5F5F5',
              }}
            />
          </Form.Item>
          <span style={{ paddingTop: '0.5rem' }}>الى</span>
          <Form.Item name="max_price">
            <Input
              placeholder="٠ ج.م"
              size="large"
              type="number"
              style={{
                width: '13.412rem',
                backgroundColor: '#F5F5F5',
              }}
            />
          </Form.Item>
        </Row>
        <div>النقاط</div>
        <Row style={{ gap: '1rem' }}>
          <span style={{ paddingTop: '0.5rem' }}>من</span>
          <Form.Item name="min_point">
            <Input
              placeholder="٠ نقطة"
              size="large"
              type="number"
              style={{
                width: '13.812rem',
                backgroundColor: '#F5F5F5',
              }}
            />
          </Form.Item>
          <span style={{ paddingTop: '0.5rem' }}>الى</span>
          <Form.Item name="max_point">
            <Input
              placeholder="٠ نقطة"
              size="large"
              type="number"
              style={{
                width: '13.412rem',
                backgroundColor: '#F5F5F5',
              }}
            />
          </Form.Item>
        </Row>
        <Row justify="start">
          <Col span={24} style={{ display: 'flex', justifyContent: 'end' }}>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <Button
                size="large"
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  color: 'red',
                }}
                onClick={() => props.form?.resetFields()}
              >
                الغاء
              </Button>
              <SaveButton
                icon={false}
                size="large"
                type="primary"
                onClick={handleSearch}
                className="bg-primary"
              >
                البحث
              </SaveButton>
            </div>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};
