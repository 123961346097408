import React from 'react';
import { useRouterContext, useRouterType, useLink } from '@refinedev/core';
import { theme, Space, Image } from 'antd';
import type { RefineLayoutThemedTitleProps } from '@refinedev/antd';
import SideLogo from '../../Assets/SideLogo.png';

export const ThemedTitleV2: React.FC<RefineLayoutThemedTitleProps> = ({
  wrapperStyles,
}) => {
  const { token } = theme.useToken();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();

  const ActiveLink = routerType === 'legacy' ? LegacyLink : Link;

  return (
    <ActiveLink
      to="/"
      style={{
        display: 'inline-block',
        textDecoration: 'none',
      }}
    >
      <Space
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: 'inherit',
          ...wrapperStyles,
        }}
      >
        <div
          style={{
            color: token.colorPrimary,
          }}
        >
          <Image src={SideLogo} />
        </div>
      </Space>
    </ActiveLink>
  );
};
