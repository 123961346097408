import { Col } from 'antd';
import React from 'react';
import LogInImage from '../../Assets/SideImage.png';

export const LogInMainImage = () => {
  return (
    <Col span={10} style={{ maxHeight: '100vh' }}>
      <img
        src={LogInImage}
        alt="login"
        style={{ height: '100%', width: '100%', display: 'block' }}
      />
    </Col>
  );
};
